$gap-xxl: 56px;
$gap-xl: 42px;
$gap-lg: 32px;
$gap-md: 24px;
$gap-sm: 16px;
$gap-xs: 8px;

.g-grid {
  display: grid;
  gap: $gap-md;

  &.--gap-xs {
    gap: $gap-xs;
  }

  &.--gap-sm {
    gap: $gap-sm;
  }

  &.--gap-md {
    gap: $gap-md;
  }

  &.--gap-lg {
    gap: $gap-lg;
  }

  &.--gap-xl {
    gap: $gap-xl;
  }

  &.--gap-xxl {
    gap: $gap-xxl;
  }

  &.--columns-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
