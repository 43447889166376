@use "./../../../scss/settings/s-colors.scss" as colors;

.s-ads__divider {
  border-bottom: 1px solid colors.$gray-200;
  margin-bottom: 32px;
}

.s-ads__divider-heading {
  margin-bottom: 8px;
  font-size: 18px;
}
