@use "settings/s-colors" as colors;

.i-social-icon {
  min-width: 48px;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: colors.$gray-100;
  border-radius: 50%;
}

.i-social-icon__image {
  width: 24px;
  height: 24px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
