@use "./../../../scss/settings/s-colors.scss" as colors;

.f-option-switch__input {
  display: none;
}

.f-option-switch__button {
  position: relative;
  padding: 16px 0;
  display: flex;
  flex-grow: 1;
  width: 100%;
  color: colors.$primary;
  font-weight: 700;
  justify-content: center;
  background-color: colors.$gray-100;
  user-select: none;
  transition: background-color 300ms ease-in-out, border-radius 200ms ease-in-out;

  &:hover {
    background-color: colors.$primary-100;
  }

  &::after {
    content: "";
    position: absolute;
    width: 1px;
    display: block;
    height: calc(100% - 16px);
    background-color: colors.$primary-100;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.f-option-switch__label {
  display: flex;
  flex-grow: 1;
  cursor: pointer;

  &:last-child .f-option-switch__button::after {
    display: none;
  }
}

.f-option-switch__input:checked + .f-option-switch__button {
  background-color: colors.$primary;
  color: colors.$white;
  font-weight: 700;

  &::after {
    display: none;
  }
}

.f-option-switch {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
  background-color: colors.$gray-100;

  &.--compact {
    display: inline-flex;

    .f-option-switch__button {
      font-weight: 400;
      min-height: 48px;
      padding: 8px 32px;
      justify-content: center;
      align-items: center;
    }
  }

  &.--size-md {

    .f-option-switch__button {
      padding: 12px 0;
    }
  }
}
