@use "./../../../scss/settings/s-colors.scss" as colors;

.mega-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: colors.$black;
  color: colors.$gray-100;
  width: 100%;
  z-index: 190;
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px);
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;

  &.--open {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);

    @include media-breakpoint-down(md) {
      height: 100vh;
      overflow-y: scroll;
    }
  }

  .footer-social-group {
    margin-bottom: 0;
  }

  .g-footer-nav {
    margin-bottom: 0;
  }

  .footer__top-content {
    display: flex;
    flex-direction: column;
    row-gap: 72px;
  }
}

.mega-menu__content {
  padding: 56px 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;

  @include media-breakpoint-down(md) {
    row-gap: 72px;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 56px;
    padding-bottom: 56px;
  }
}

.mega-menu__region {
  grid-column: span 6 / auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;

  @include media-breakpoint-down(md) {
    grid-column: 1/-1;
  }
}

.mega-menu__user {
  order: -1;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.mega-menu__logo {
  order: 4;

  @include media-breakpoint-up(md) {
    display: none;
  }

  img {
    margin-inline: auto;
    max-width: 100%;
    height: 24px;
  }
}

.mega-menu__navigation {
  grid-column: span 3 / auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;

  &.--main {

    @include media-breakpoint-down(md) {
      order: -1;
    }
  }

  &.--language {

    @include media-breakpoint-down(md) {
      order: 3;
    }
  }

  @include media-breakpoint-down(md) {
    grid-column: 1/-1;
    padding: 8px 19px 0;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 1px;
      background-color: colors.$gray-300;
    }
  }
}

.mega-menu__heading {
  font-size: 26px;
  font-weight: 400;
}

.mega-menu__list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mega-menu__region-map {
  max-width: 525px;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.mega-menu__region-select {
  display: none;

  @include media-breakpoint-down(md) {
    display: block;
  }
}
