@use "./../../../scss/settings/s-colors.scss" as colors;

.g-info {
  display: flex;
  margin-bottom: 0;
  gap: 24px;

  @include media-breakpoint-down(xxl) {
    gap: 16px;
  }

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    gap: 0;
  }
}

.g-info__col {
  border-right: 1px solid colors.$gray-200;
  width: 33.333%;

  &:last-child {
    border-right: none;
  }

  .i-info {
    padding-right: 16px;

    @include media-breakpoint-down(xxl) {
      padding-right: 8px;
    }
  }

  @include media-breakpoint-down(lg) {
    width: 100%;
    border-right: none;
  }
}
