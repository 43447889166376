@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.i-overview__photo-count {
  line-height: general.$spacing * 2;
  font-size: general.$spacing * 2;
  align-self: center;
  color: colors.$white;
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
}

.i-overview__camera {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
}

.i-overview__three-dimensional {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
}

.i-overview__videocamera {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
}

.i-overview {
  position: relative;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(2, 24px);
  grid-template-rows: repeat(2, 24px);
  justify-content: center;
  align-content: center;
  text-align: center;
  height: general.$spacing * 10;
  width: general.$spacing * 10;
  border-radius: 24px;
  z-index: 5;
  pointer-events: none;

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: colors.$gray-600;
    opacity: 0.75;
    border-radius: 24px;
    z-index: -1;
    pointer-events: none;
  }

  &.--small {
    grid-template-columns: repeat(2, 24px);
    grid-template-rows: 24px;
    height: general.$spacing * 5;

    .i-overview__photo-count {
      grid-column: 2 / span 1;
      grid-row: 1 / span 1;
    }

    .i-overview__camera {
      grid-column: 1 / span 1;
      grid-row: 1 / span 1;
    }
  }
}
