@use "./../../../scss/settings/s-general.scss" as general;

.g-category-card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: general.$columnGap;
  margin-bottom: 25px;

  @include media-breakpoint-down(md) {
    gap: 8px;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(12, 1fr);
  }
}

.g-category-card__cell {

  @include media-breakpoint-up(md) {
    grid-column: span 4 / auto;

    &:nth-child(-n+2) {
      grid-column: span 6 / auto;
      grid-row: span 1 / auto;
    }
  }

  @include media-breakpoint-up(xl) {
    grid-column: span 2 / auto;

    &:nth-child(-n+2) {
      grid-column: span 3 / auto;
      grid-row: span 2 / auto;
    }
  }
}
