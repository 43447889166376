@use "./../../../scss/settings/s-colors.scss" as colors;

.i-category-nav-item__link {
  text-decoration: none;
  color: colors.$primary;
  font-weight: 18px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 64px;
  transition: color 0.3s ease;
  transition-property: color, background-color;

  &:hover {
    background-color: colors.$primary;
    color: colors.$white;
  }
}

.i-category-nav-item {
  display: block;
  font-size: 18px;
  flex-grow: 1;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    display: block;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: calc(100% - 26px);
    width: 1px;
    background-color: colors.$primary-100;
    z-index: 1;
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  &:last-child {

    &::after {
      opacity: 0;
    }
  }

  &.--active {

    &::after {
      opacity: 0;
    }

    .i-category-nav-item__link {
      background-color: colors.$primary;
      color: colors.$white;
    }
  }
}
