@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.g-footer-nav {
  column-count: 2;
  column-gap: general.$spacing * 2;
  margin-bottom: general.$spacing * 7;

  li {
    margin-bottom: general.$spacing * 2;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-down(xl) {
    column-count: 1;
  }

  @include media-breakpoint-down(md) {
    column-count: 2;
    margin-bottom: general.$spacing * 8;
  }

  @include media-breakpoint-down(sm) {
    column-count: 1;
  }
}
