@use "./../../../scss/settings/s-colors.scss" as colors;

.i-agent__body {
    padding: 16px;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    overflow: hidden;
}

.i-agent__header {
    display: flex;
    flex-direction: column;
}

.i-agent__heading {
    font-size: 21px;
    font-weight: bold;
}

.i-agent__image-cell {
    flex-shrink: 0;
    flex: 0 0 176px;
}

.i-agent__position {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 8px;
    text-align: center;
    color: colors.$white;
    background: transparent linear-gradient(180deg, #2e3a4300 0%, #2e3a43 100%) 0% 0% no-repeat padding-box;
    border-radius: 0 0 8px 8px;
}

.i-agent__socials {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    width: 100%;
    position: relative;

    &::after {
        content: "";
        pointer-events: none;
        position: absolute;
        display: block;
        right: 0;
        top: 0;
        width: 66px;
        height: 100%;
        background: transparent linear-gradient(270deg, #fff 0%, #fff0 100%) 0% 0% no-repeat padding-box;
    }
}

.i-agent {
    display: flex;
    gap: 24px;
    border-radius: 10px;
    box-shadow: colors.$shadow-01;
}

.i-agent__heading-link {
    display: block;
    //color: colors.$primary;
    color: inherit;
    text-decoration-color: rgba(colors.$black, 0);
    transition: text-decoration-color .3s ease-in-out;
    &:hover {
        text-decoration-color: colors.$black;
        color: inherit;
    }
}

.i-agent__image-link {
    display: block;
    position: relative;
    aspect-ratio: 1/1;
    border-radius: 10px;
    background-color: colors.$gray-100;
}
