.i-bar-person {
  gap: 56px;
}

.i-bar-person__label {
  font-size: 21px;
  line-height: 27px;
  font-weight: bold;
}

.i-bar-person__title {
  font-size: 21px;
  line-height: 27px;
}
