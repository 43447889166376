@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.i-broker-list-chart {
  display: flex;
  align-items: center;
  gap: 24px;
}

.i-broker-chart__chart {
  width: 130px;
  aspect-ratio: 1/1;
  display: block;
  position: relative;
}

.i-broker-chart__total {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.i-broker-chart__total-count {
  color: colors.$gray-600;
}

.i-broker-chart__unit {
  color: colors.$gray-300;
}

.i-broker-chart__legend {
  display: flex;
  flex-direction: column;
}

.i-broker-chart__legend-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 28px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    height: general.$spacing * 2;
    width: general.$spacing * 2;
    transform: translate(0%, -50%);
    border-radius: 50%;
  }

  &:first-of-type {

    &::before {
      background-color: colors.$flat;
    }
  }

  &:nth-of-type(2) {

    &::before {
      background-color: colors.$house;
    }
  }

  &:nth-of-type(3) {

    &::before {
      background-color: colors.$land;
    }
  }

  &:nth-of-type(4) {

    &::before {
      background-color: colors.$commercial;
    }
  }

  &:nth-of-type(5) {

    &::before {
      background-color: colors.$other-reality;
    }
  }
}

.i-broker-chart__legend-item-title {
  color: colors.$gray-600;
}

.i-broker-chart__legend-item-count {
  color: colors.$gray-300;
}
