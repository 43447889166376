@use "./../../scss/settings/s-colors.scss" as colors;

$sizeXs: 8px;
$sizeSm: 14px;
$sizeMd: 16px;
$sizeLg: 24px;
$sizeXl: 32px;

.icon {
  color: colors.$black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 $sizeMd;
  max-width: $sizeMd;
  height: $sizeMd;
  position: relative;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;

  > svg {
    width: 100%;
    height: 100%;
  }

  &.--primary {
    color: colors.$primary;
  }

  &.--white {
    color: colors.$white;

    path,
    rect {
      stroke: colors.$white;
    }
  }

  &.--yellow {
    color: colors.$conversion;

    path,
    line,
    rect {
      stroke: colors.$conversion;
    }
  }

  &.--red {
    color: colors.$red;

    path,
    line,
    rect {
      stroke: colors.$red;
    }
  }

  &.--xl {
    flex: 0 0 $sizeXl;
    max-width: $sizeXl;
    height: $sizeXl;
  }

  &.--lg {
    flex: 0 0 $sizeLg;
    max-width: $sizeLg;
    height: $sizeLg;
  }

  &.--sm {
    flex: 0 0 $sizeSm;
    max-width: $sizeSm;
    height: $sizeSm;
  }

  &.--xs {
    flex: 0 0 $sizeXs;
    max-width: $sizeXs;
    height: $sizeXs;
  }

  .icon__badge {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(50%) translateY(-50%);

    >.badge {
      display: flex;
    }
  }
}
