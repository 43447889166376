@use "./../../../scss/settings/s-colors.scss" as colors;

.i-usp {
  display: flex;
  align-items: center;
  column-gap: 43px;

  @include media-breakpoint-down(xl) {
    column-gap: 24px;
  }

  @include media-breakpoint-down(lg) {
    column-gap: 32px;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 8px;
  }
}

.i-usp__image {
  background-color: colors.$white;
  border-radius: 50%;
  position: relative;
  width: 115px;
  height: 115px;
  box-shadow: 0 5px 11px 0 rgba($color: #000, $alpha: 11%), 0 0 0 11px colors.$primary-100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  .icon {
    max-width: 50px;
    height: 50px;
    object-fit: contain;
    flex: 50px;
  }

  @include media-breakpoint-down(xxl) {
    width: 90px;
    height: 90px;
  }

  @include media-breakpoint-down(xl) {
    width: 80px;
    height: 80px;
  }

  @include media-breakpoint-down(lg) {
    width: 115px;
    height: 115px;
  }
}

.i-usp__heading {
  margin-bottom: 8px;
  font-size: 21px;
  font-weight: bold;

  @include media-breakpoint-down(md) {

    br {
      display: none;
    }
  }
}

.i-usp__perex {
  max-width: 180px;
  font-size: 16px;
}
