@use "./../../scss/settings/s-colors.scss" as colors;

.box__content {
  background-color: colors.$gray-100;
  padding: 24px;
  border-radius: 10px;

  @include media-breakpoint-down(sm) {
    padding-left: 16px;
    padding-right: 16px;
  }
}
