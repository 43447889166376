%base-h1 {
  font-size: 42px;
  line-height: 48em/42;
  font-weight: 700;
}

%base-h2 {
  font-size: 26px;
  line-height: calc(32em/26);
  font-weight: 700;
}

%base-h3 {
  font-size: 21px;
  line-height: calc(24em/21);
  font-weight: 700;
}

%base-h4 {
  font-size: 18px;
  line-height: calc(24em/18);
  font-weight: 700;
}

.base-h1 {

  @extend %base-h1;
}

.base-h2 {

  @extend %base-h2;
}

.base-h3 {

  @extend %base-h3;
}

.base-h4 {

  @extend %base-h4;
}
