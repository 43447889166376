@use "./../../../scss/settings/s-general.scss" as general;

@use "./../../../scss/settings/s-colors.scss" as colors;

.s-estate-detail-contact-seller-modal {
    .base-modal__content {
        padding: 34px;
        > .base-header {
            h2 {
                @include media-breakpoint-down(sm) {
                    font-size: 2rem;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            padding-bottom: 0;
        }
    }

    .base-header {
        margin-bottom: 0;
    }
    .i-estate {
        margin-top: 26px;
        margin-bottom: 26px;
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        grid-gap: 0;
        .i-estate__header-title {
            font-weight: 400;
            font-size: 26px;
            line-height: 32px;
        }
        .i-estate__description {
            mask-image: unset;
            .i-estate__description-text {
                min-height: unset;
                font-size: 18px;
                line-height: 21px;
            }
        }
        .i-estate__footer-price-value {
            font-size: 26px;
            line-height: 30px;
        }
        .i-estate__image {
            @include media-breakpoint-up(md) {
                //max-height: 180px;
                max-width: 280px;
                .img {
                    height: 100%;
                }
            }
        }
    }

    .realtor-info {
        padding-bottom: 34px;
        margin-top: 69px;
    }

    form {
        .inputs {
            display: grid;
            grid-gap: 12px;
            @include media-breakpoint-up(md) {
                grid-template-columns: 1fr 1fr;
                .colspan2 {
                    grid-column: 1 / span 2;
                }
            }

            textarea {
                min-height: 86px;
            }
        }
        footer {
            margin-top: 29px;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            .btn[type="submit"] {
                margin-top: 29px;
            }
            @include media-breakpoint-up(lg) {
                flex-flow: row nowrap;
                justify-content: space-between;
                .btn[type="submit"] {
                    margin-left: 148px;
                    margin-top: 0;
                }
            }
            .checkbox {
                .f-label__title {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
            [for="estate-detail-contact-seller-gdpr"] {
                cursor: unset;
                &::before {
                    opacity: 0;
                }
            }
            .btn[type="submit"] {
                .btn__title {
                    white-space: nowrap;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        padding-top: 5rem;
    }
}
