@use "./../../../scss/settings/s-colors.scss" as colors;

.s-usp {
  background-color: colors.$gray-100;
}

.s-usp__wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 124px;

  @include media-breakpoint-down(lg) {
    margin-bottom: 66px;
    flex-wrap: wrap;
  }
}

.s-usp__image {
  width: 50%;
  flex-shrink: 0;

  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.s-usp__heading {
  margin-right: 124px;

  @include media-breakpoint-down(lg) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 50px;
  }

  .base-header__heading {
    width: 60%;
    margin-bottom: 55px;

    @include media-breakpoint-down(xxl) {
      width: 80%;
    }

    @include media-breakpoint-down(xl) {
      width: 100%;
    }

    @include media-breakpoint-down(lg) {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  .base-header__paragraph {
    font-size: 26px;
    font-weight: 400;
  }
}
