@use "../settings/s-fonts.scss" as fonts;
@use "../settings/s-colors.scss" as colors;

body {
    font-family: fonts.$primary;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale !important;
    font-size: 16px;
    line-height: 24px;
    overflow-x: hidden;
    color: colors.$black;
}
