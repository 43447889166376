@use "./../../../scss/settings/s-colors.scss" as colors;

.i-filter-table {

  &.--empty {
    opacity: 0.15;
    pointer-events: none;
  }
}

.i-filter-table__empty-title {
  max-width: 90%;
  height: 16px;
  border-radius: 8px;
  background-color: colors.$primary;
}

.i-filter-table__empty-counter {
  max-width: 90%;
  height: 16px;
  border-radius: 8px;
  background-color: colors.$gray;
}

.i-filter-table:nth-child(1) {

  .i-filter-table__empty-title {
    width: 137px;
  }

  .i-filter-table__empty-counter {
    width: 45px;
  }
}

.i-filter-table:nth-child(2) {

  .i-filter-table__empty-title {
    width: 241px;
  }

  .i-filter-table__empty-counter {
    width: 31px;
  }
}

.i-filter-table:nth-child(3) {

  .i-filter-table__empty-title {
    width: 193px;
  }

  .i-filter-table__empty-counter {
    width: 46px;
  }
}

.i-filter-table:nth-child(4) {

  .i-filter-table__empty-title {
    width: 297px;
  }

  .i-filter-table__empty-counter {
    width: 36px;
  }
}

.i-filter-table:nth-child(5) {

  .i-filter-table__empty-title {
    width: 241px;
  }

  .i-filter-table__empty-counter {
    width: 20px;
  }
}

.i-filter-table:nth-child(6) {

  .i-filter-table__empty-title {
    width: 257px;
  }

  .i-filter-table__empty-counter {
    width: 36px;
  }
}

.i-filter-table:nth-child(7) {

  .i-filter-table__empty-title {
    width: 209px;
  }

  .i-filter-table__empty-counter {
    width: 45px;
  }
}
