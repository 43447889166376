@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.g-share-buttons {
  display: flex;
  gap: 10px;
  transform-origin: left center;
  transition: transform 0.2s, opacity 0.2s;
  pointer-events: none;
  overflow: hidden;
  opacity: 0;
  z-index: -1;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    gap: 8px;
  }
}
