.i-register-benefit__icon {
  max-width: 30px;
  flex: 0 0 30px;
  height: 30px;
  transform: translateY(4px);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
