@use "./../../../scss/settings/s-general.scss" as general;

@use "./../../../scss/settings/s-colors.scss" as colors;

.ss-current-info__heading {
  color: colors.$gray-300;
  width: 400px;
  padding-right: 180px;

  @include media-breakpoint-down(xxl) {
    width: 100%;
    padding-right: 0;
    text-align: center;
    margin-bottom: general.$spacing * 7;
  }

  @include media-breakpoint-down(xl) {
    margin-bottom: 0;
  }
}

.ss-current-info {
  display: flex;
  align-items: center;
  position: relative;
  padding: general.$spacing * 8 0;
  margin-top: general.$spacing * 14;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    height: 1px;
    width: 100%;
    transform: translate(-50%, -50%);
    background-color: colors.$gray-200;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 1px;
    width: 100%;
    transform: translate(-50%, -50%);
    background-color: colors.$gray-200;
  }

  @include media-breakpoint-down(xxl) {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: general.$spacing * 10;
  }

  @include media-breakpoint-down(xl) {
    flex-direction: column;
  }

  @include media-breakpoint-down(lg) {
    padding: general.$spacing * 3 0;
  }
}
