@use "./../../../scss/settings/s-general.scss" as general;

@use "./../../../scss/settings/s-colors.scss" as colors;

.base-section-side-menu__container {
    display: flex;
    flex-direction: column;
    column-gap: 16px;

    @include media-breakpoint-up(xl) {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
    }
}

.base-section-side-menu {
    padding-top: 56px;
    padding-bottom: general.$spacing * 10;

    @include media-breakpoint-down(lg) {
        padding-top: general.$spacing * 7;
        padding-bottom: general.$spacing * 7;
    }

    @include media-breakpoint-down(sm) {
        padding-top: general.$spacing * 5;
        padding-bottom: general.$spacing * 5;
    }

    &.--p-top-0 {
        padding-top: 0;
    }

    &.--p-bottom-0 {
        padding-bottom: 0;
    }

    &.--p-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    &.--primary-100 {
        background-color: colors.$primary-100;
    }

    &.--border-top {

        .base-section-side-menu__container {
            padding-top: 16px;
            position: relative;

            &::before {
                content: " ";
                width: calc(100% - 16px);
                height: 1px;
                background: colors.$gray-200;
                position: absolute;
                top: 0;
                left: 8px;
                box-sizing: border-box;
            }
        }
    }
}

.base-section-side-menu__side-menu {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 100%;

    @include media-breakpoint-up(xl) {
        grid-column: span 3 / auto;
    }

    @include media-breakpoint-down(xl) {
        order: -1;
    }
}

.base-section-side-menu__content {

    @include media-breakpoint-up(xl) {
        grid-column: span 9 / auto;
    }
}
