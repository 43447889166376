.g-ad {
  display: flex;
  flex-direction: column;
  gap: 16px;
  row-gap: 56px;

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
