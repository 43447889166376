@use "./../../../scss/settings/s-general.scss" as general;

.g-heighlited-broker {
  grid-column-end: 0;
  grid-column-end: span 8;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: general.$spacing * 6 18px;

  li {
    grid-column-end: span 4;
  }

  @include media-breakpoint-down(xxl) {
    grid-column-end: span 12;
  }

  @include media-breakpoint-down(md) {
    gap: general.$spacing * 8 0;

    li {
      grid-column-end: span 8;
    }
  }
}
