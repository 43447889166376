@use "./../../scss/settings/s-colors.scss" as colors;

@use "./../../scss/settings/s-general.scss" as general;

.overview-button {
  position: relative;
  gap: 10px;
  display: inline-flex;
  width: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: general.$spacing * 6;
  border-radius: 24px;
  z-index: 5;
  padding: general.$spacing * 1.5;
  min-width: 40px;
  text-decoration: none;
  color: colors.$white;
  border: none;
  background-color: transparent;

  .overview-button__photo-count {
    line-height: general.$spacing * 2;
    font-size: general.$spacing * 2;
    align-self: center;
    color: colors.$white;
    margin-right: general.$spacing;
  }

  .overview-button__camera {
    margin-left: general.$spacing;
  }

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: colors.$gray-600;
    opacity: 0.75;
    border-radius: 24px;
    z-index: -1;
    pointer-events: none;
    transition: background-color 0.2s, opacity 0.2s;
  }

  &:hover {
    cursor: pointer;

    &::before {
      background-color: colors.$primary;
      opacity: 1;
    }
  }
}
