@use "./../../../scss/settings/s-general.scss" as general;

.s-real-estate {
  position: relative;
}

.s-real-estate__content-block {

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 5fr 7fr;
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: 4fr 8fr;
    gap: 120px;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: general.$spacing * 10;
  }
}

.s-real-estate__content {
  grid-column: 1 / span 1;
}

.s-real-estate__subheading {
  margin-bottom: general.$spacing * 5;
}

.s-real-estate__perex {
  margin-bottom: 0;
}

.s-real-estate__decor-img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(calc(-960px - 69%), -17%);
}
