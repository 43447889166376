@use "./../../scss/settings/s-colors.scss" as colors;


ul.filters-list {
    // ROW
    > li {
        display: grid;
        grid-template-columns: minmax(0, 717fr) minmax(0, 336fr) minmax(0, 112px);
        align-items: center;

        // CELL
        > * {
            padding: 6px 14px;

            &.name {
                .link__title-wrap {
                    font-size: 18px;
                    line-height: 24px;
                    white-space: normal;
                }
            }

            &.double-column {
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                justify-content: center;
                text-align: center;
                p {
                    &:first-child {
                        justify-self: flex-end;
                    }
                    font-size: 16px;
                    line-height: 22px;
                    margin-bottom: 0;
                }
                .tag {
                    background-color: colors.$red;
                    color: white;
                    border-radius: 900px;
                    padding: 1px 10px;
                    font-size: 13px;
                    line-height: 16px;
                }

                @include media-breakpoint-up(lg) {
                    display: grid;
                    align-items: center;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    p {
                        text-align: right;
                        font-size: 16px;
                        line-height: 22px;
                        + p {
                            text-align: left;
                            margin-left: 10px;
                        }
                    }
                    .tag {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
            }

            &.cta {
                padding-bottom: 9px;
                display: flex;
                justify-content: center;
                p {
                    margin-bottom: 0;
                }
            }

            .btn {
                &.--white {
                    .btn__title {
                        font-weight: 400;
                    }
                }
            }
        }

        &.header {
            .heading {
                display: flex;
                align-items: center;
                flex-flow: row nowrap;
                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 48px;
                    width: 48px;
                    min-width: 48px;
                    max-width: 48px;
                    margin-right: 10px;
                    background-color: colors.$gray-100;
                    border-radius: 50%;
                    svg, img {
                        max-height: 17px;
                        max-width: 17px;
                    }
                }
                .base-header {
                    margin-bottom: 0;
                }
            }
            p {
                color: colors.$gray;
            }
            .cta, .count {
                align-self: flex-end;
            }
        }

        + li {
            border-top: 1px solid colors.$primary-150;
        }
    }

    &.--with-column-edit {
        > li {
            grid-template-columns: minmax(0, 717fr) minmax(0, 310px) minmax(0, 112px) minmax(0, 190px);
        }
    }
    &.--with-column-delete {
        > li {
            grid-template-columns: minmax(0, 717fr) minmax(0, 310px) minmax(0, 112px) minmax(0, 60px);
        }
    }
    &.--with-column-edit.--with-column-delete {
        > li {
            grid-template-columns: minmax(0, 717fr) minmax(0, 310px) minmax(0, 112px) minmax(0, 190px) minmax(0, 60px);
        }
    }

    + ul.filters-list,
    + .btn {
        margin-top: 31px;
    }

    .btn {
        &.--icon-before {
            column-gap: 11px;
        }
        &.--white {
            .btn__icon {
                .icon {
                    height: 100%;
                    max-width: 24px;
                    svg {
                        height: unset;
                        width: unset;
                    }
                }
                path {
                    fill: none;
                }
            }
        }
    }


    @media screen and (max-width: 1200px) {
        &.--with-column-edit.--with-column-delete {
            > li {
                &.header {
                    display: none;
                }

                grid-template-columns: minmax(0, 1fr) minmax(0, 48px) minmax(0, 56px) minmax(0, 48px);
                grid-template-rows: repeat(2, auto);
                > .name {
                    grid-column: 1 / 2;
                    grid-row: 1 / 2;
                    padding-bottom: 0;
                    padding-top: .75rem;
                    padding-left: 6px;
                }
                > .count {
                    padding-left: 6px;
                    padding-bottom: .75rem;
                    grid-column: 1 / 2;
                    grid-row: 2 / 3;
                    &.double-column {
                        display: flex;
                        align-items: center;
                        flex-flow: row nowrap;
                        justify-content: flex-start;
                        gap: .5rem;
                    }
                }
                > .cta,
                > .edit,
                > .delete {
                    grid-row: 1 / 3;
                    padding-left: 0;
                    padding-right: 0;
                    justify-content: center;
                }
                > .edit {
                    padding-left: 8px;
                    > .btn {
                        > .btn__title {
                            display: none;
                        }
                        display: flex;
                        height: 3rem;
                        justify-content: center;
                        padding: 0;
                        width: 3rem;
                    }
                }
            }
            @media screen and (max-width: 480px) {
                > li {
                    grid-template-columns: minmax(0, 1fr) minmax(0, 48px) minmax(0, 56px);
                    .delete {
                        display: none;
                    }
                }
            }
        }
    }
}

body[data-layout=user-section] {
    >main {
        .user-section-main {
            .filters {
                > .base-header {
                    &:not(:last-child) {
                        @media screen and (max-width: 600px) {
                            margin-bottom: 0;
                            + .filters-list {
                                margin-top: 19px;
                            }
                        }

                    }
                }
            }
        }
    }
}
