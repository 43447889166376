@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.i-estate-slide {
    box-shadow: colors.$shadow-01;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(md) {

        .i-overview {
            display: none;
        }
    }
}

.i-estate-slide__image-block {
    position: relative;

    .i-overview {
        position: absolute;
        bottom: general.$spacing * 2;
        right: general.$spacing * 2;
    }
}

// Img

.i-estate-slide__image-link {
    display: block;

    .img,
    img {
        border-radius: 10px 10px 0 0;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.i-estate-slide__cta-button {
    position: absolute;
    bottom: 0;
    left: 16px;
    transform: translateY(40%);
}

.i-estate-slide__content {
    padding: 28px 22px 36px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.i-estate-slide__heading {
    margin-bottom: general.$spacing;
    font-weight: 400;
}

.i-estate-slide__heading-link {
    text-decoration-line: underline;
    text-decoration-color: transparent;
    text-decoration-style: solid;
    text-decoration-thickness: inherit;
    transition: text-decoration-color 0.2s;
    color: colors.$black;

    &:hover {
        color: colors.$black;
        text-decoration-color: colors.$black;
    }
}

.i-estate-slide__price {
    font-size: 26px;
    line-height: general.$spacing * 3;
    font-weight: bold;
    display: flex;
    gap: 9px;
    margin-top: auto;
}

.i-estate-slide__badges {
    position: absolute;
    left: 24px;
    top: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
