.g-main-filter {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @include media-breakpoint-down(xl) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
  }

  .box {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .box__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.g-main-filter__header {
  margin-bottom: 8px;
}

.g-main-filter__heading {
  font-size: 26px;
  line-height: 24px;
  font-weight: 400;
}

.g-main-filter__cell-price {

  @include media-breakpoint-down(xl) {
    grid-column: 1/-1;
  }
}

.g-main-filter__cell {
  display: flex;
  flex-direction: column;

  > .g-main-filter__header {
    margin-left: 1.5rem;
  }
}
