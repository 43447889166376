@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.s-estates-slide {
    overflow: hidden;

    .swiper-slide {
        height: auto;
    }
}

body[data-layout="user-section"] {
    .s-estates-slide {
        overflow: visible;
    }
}

.header-with-dropdown {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    > .dropdown__wrapper {
        justify-self: flex-end;
    }
}

.s-estates-slide__slider {
    overflow: visible;
}

.s-estates-slide__bottom-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 32px;
    padding: 0 8px;
    margin-top: 24px;
    user-select: none;
}

.s-estates-slide__container {

    @include media-breakpoint-down(md) {
        max-width: 312px;
    }
}


body[data-layout="user-section"] {
    .user-section-main {
        .s-estates-slide {

            > .container {
                max-width: 100%;
            }
            &.user-dashboard-favourite-real-estates {
                margin-top: 112px;
                > .base-section__container {
                    > .header-with-dropdown {
                        > .base-header {
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
    }

}