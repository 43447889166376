@use "./../../../scss/settings/s-colors.scss" as colors;

.i-branch {
  display: flex;
  box-shadow: 0 7px 16px #00000029;
  border-radius: 8px;
}

.i-branch__content {
  padding: 10px 72px 10px 20px;

  @include media-breakpoint-down(md) {
    padding: 12px 22px 12px 16px;
  }
}

.i-branch__image {
  width: 176px;
  height: 176px;
  flex-shrink: 0;

  .img {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px 0 0 8px;
  }

  @include media-breakpoint-down(md) {
    width: 100px;
  }
}

.i-branch__title {
  line-height: 1;
  text-decoration: none;
  margin-bottom: 2px;

  h3 {
    font-size: 18px;
    color: colors.$black;
    line-height: 1;
    font-weight: 700;
    margin-bottom: 0;
  }
}

.i-branch__type {
  font-style: italic;
  margin-bottom: 8px;
  color: colors.$gray-300;
}

.i-branch__address {
  margin-bottom: 8px;
  line-height: 24px;
  max-height: 69px;
  overflow-y: hidden;
  flex-grow: 1;
}
