.f-login {
  max-width: 328px;
  margin-inline: auto;
}

.f-login__gdpr {

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.f-login__forgotten-password {

  @include media-breakpoint-down(lg) {
    justify-content: center;
  }
}

.f-login__heading {
  font-size: 26px;
}
