@use "settings/s-colors" as colors;

.i-divider {
  margin-top: 8px;
  margin-bottom: 8px;

  &::after,
  &::before {
    content: "";
    flex-grow: 1;
    width: 100%;
    height: 1px;
    background-color: colors.$gray-200;
  }
}
