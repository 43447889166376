.g-badges {
  display: flex;
  gap: 8px;

  &.--horizontal {
    align-items: flex-start;
  }

  &.--vertical {
    flex-direction: column;
  }
}
