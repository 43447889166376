@use "./../../../scss/settings/s-colors.scss" as colors;

.mega-menu-item {
  display: block;
}

.mega-menu-item__link {
  display: block;
  color: colors.$gray-100;
  text-decoration-color: transparent;

  &:hover {
    color: colors.$gray-100;
    text-decoration-color: colors.$gray-100;
  }
}
