@use "./../../../scss/settings/s-colors.scss" as colors;

.s-map {

    .f-search {
        width: 576px;
        max-width: 100%;
        margin: 0 auto 64px;

        .btn {
            height: 100%;
        }

        @include media-breakpoint-down(md) {
            margin: 0 auto 46px;
        }
    }

    .f-base {
        background-color: colors.$white;
    }

    .map-image {
        max-width: 996px;
        margin-inline: auto;

        svg {
            margin: 0 auto;
            display: block;
            max-width: 100%;
        }

        a {
            text-decoration: none;
        }
    }

    #Component_133_1,
    #Component_134_1,
    #Component_132_1,
    #Component_131_1,
    #Component_130_1,
    #Component_129_1,
    #Component_128_1 {
        cursor: pointer;

        #Group_1002,
        #Group_1001,
        #Group_1000,
        #Group_999,
        #Group_997,
        #Group_998,
        #Group_996 {
            transition: transform 0.2s ease;
            transform-origin: center;
        }

        #Path_1883,
        #Path_1884,
        #Path_1882,
        #Path_1878,
        #Path_1879,
        #Path_1880,
        #Path_1881 {
            transition: fill 0.2s ease;
        }

        &:hover {

            #Path_1883,
            #Path_1884,
            #Path_1882,
            #Path_1878,
            #Path_1879,
            #Path_1880,
            #Path_1881 {
                opacity: 1;
                fill: colors.$conversion;
            }
        }
    }
}
