img {
  display: block;
  opacity: 1;
  transition: opacity 0.5s ease;
  height: auto;
  max-width: 100%;
  will-change: opacity;

  &[data-src],
  &[data-srcset] {
    display: block;
    min-height: 1px;
    min-width: 1px;
  }

  &[data-src]:not(.loaded) {
    opacity: 0;
  }
}
