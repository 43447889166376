@use "./../../scss/settings/s-colors.scss" as colors;

@use "./../../scss/settings/s-general.scss" as general;

.pagination-arrow {
  background-color: colors.$gray-100;
  height: general.$spacing * 5;
  width: general.$spacing * 5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s;

  polyline {
    transition: stroke 0.2s;
  }

  &.--previous {
    margin-right: 4px;

    svg {
      transform: rotate(180deg);
    }
  }

  &.--next {
    margin-left: 4px;
  }

  &.--disabled {
    pointer-events: none;

    polyline {
      stroke: colors.$gray-300;
    }
  }

  &:hover {
    background-color: colors.$primary;
    cursor: pointer;

    polyline {
      stroke: colors.$white;
    }
  }
}
