@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.i-count {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 82px;
  padding-right: 63px;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 30px;
    transform: translateY(-50%) rotate(45deg);
    border-radius: 5px;
    background-color: colors.$conversion;
  }

  @include media-breakpoint-down(xxl) {
    padding-left: 145px;

    &::before {
      left: 63px;
    }
  }

  @include media-breakpoint-down(xl) {
    flex-direction: row;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    padding-top: general.$spacing * 7;

    &::before {
      left: 50%;
      top: general.$spacing * 2;
      transform: translate(-50%, 0) rotate(45deg);
    }

    &:first-of-type {

      &::before {
        display: none;
      }
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: general.$spacing * 5;

    &::before {
      width: 20px;
      height: 20px;
      top: 20px;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}

.i-count__number {
  font-size: 120px;
  line-height: general.$spacing * 16;
  font-weight: bold;

  @include media-breakpoint-down(xl) {
    padding-right: 18px;
  }

  @include media-breakpoint-down(md) {
    font-size: 63px;
    line-height: general.$spacing * 8;
  }
}

.i-count__label {
  color: colors.$gray-300;

  @include media-breakpoint-down(xl) {
    max-width: 100px;
  }
}
