@use "./../../../scss/settings/s-general.scss" as general;

.g-buttons {
  margin-top: general.$spacing * 2;

  &.--large-indentation {
    margin-top: 48px;
  }

  &.--center {
    justify-content: center;
  }

  &.--right {
    justify-content: flex-end;
  }

  &.--left {
    justify-content: flex-start;
  }

  &.--between {
    justify-content: space-between;
  }

  &.--mobile-center {

    @include media-breakpoint-down(md) {
      justify-content: center;
    }
  }

  &.--mobile-right {

    @include media-breakpoint-down(md) {
      justify-content: flex-end;
    }
  }

  &.--mobile-left {

    @include media-breakpoint-down(md) {
      justify-content: flex-start;
    }
  }

  &.--m-t-sm {
    margin-top: 24px;
  }

  &.--m-t-none {
    margin-top: 0;
  }
}
