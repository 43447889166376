@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.i-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    height: general.$spacing * 5;
    width: general.$spacing * 5;
    text-decoration: none;
    color: colors.$gray;
    transition: color 0.2s, background-color 0.2s;
    border-radius: 50%;
    appearance: none;
    border: none;
    background-color: transparent;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;

    &:hover,
    &:focus {
        background-color: colors.$primary-100;
        border: none;
        color: colors.$gray-600;
    }

    &.--active {
        background-color: colors.$primary;
        color: colors.$white;
        pointer-events: none;
    }
}

.i-pagination__wrapper {
    display: flex;
    flex-direction: column;

    label {
        order: -1;
    }

    .choices:focus + label {
        background-color: colors.$primary-100;
        border: none;
        color: colors.$gray-600;
    }

    .choices {
        width: general.$spacing * 5;
        height: 0;
        border: 0;
        margin-bottom: 0;

        &::after {
            display: none;
        }
    }

    .choices__inner {
        height: 0;
        padding: 0 !important;
        border: 0;
        width: 48px;
    }

    .choices__inner + .choices__list {
        outline: none;
        border: none;
        padding: 0 !important;
        position: absolute;
        overflow: visible;
        top: 4px;
        border-radius: general.$border-base;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: colors.$shadow-01;
            background-color: colors.$white;
            border-radius: general.$border-base;
        }

        .choices__list {
            max-height: 104px;
            overflow: auto;

            &::-webkit-scrollbar {
                width: 8px;
                border-radius: 8px;
            }

            &::-webkit-scrollbar-thumb {
                background: colors.$gray-200;
                width: 8px;
                height: 12px;
                min-height: 12px;
                max-height: 12px !important;
                border-radius: 8px;
            }
        }

        .choices__item {
            font-size: 18px;
            line-height: 22px;
            padding: general.$spacing * 0.5;
            text-align: center;

            &.is-highlighted,
            &:hover {
                background-color: transparent;
            }

            &::after {
                display: none;
            }
        }
    }
}

.i-pagination__wrapper.options {
    position: relative;
    .i-pagination__select {
        display: none;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-radius: general.$border-base;
        box-shadow: colors.$shadow-01;
        background-color: colors.$white;
        text-align: center;
        padding: 3px 0;
        z-index: 2;
        min-width: 2.5em;
        a {
            padding: .2em .5em;
            text-decoration: none;
            color: colors.$black;
            &:hover {
                text-decoration: underline;
            }
            &:first-child {
                padding-top: .4em;
            }
            &:last-child {
                padding-bottom: .4em;
            }
        }
    }
    &:hover {
        .i-pagination {
            background-color: colors.$primary-100;
        }
        .i-pagination__select {
            display: block;
        }
    }
}