@use "./../../../scss/settings/s-colors.scss" as colors;

.i-rating__content {
  display: flex;
  gap: 8px;
}

.i-rating__legend {
  font-size: 14px;
  font-style: italic;
  color: colors.$gray-300;
  display: flex;
  gap: 8px;

  &.--large {
    font-size: 18px;
    gap: 0;
    color: colors.$gray-600;
    font-style: inherit;
  }

  &.--left {
    align-items: center;
    order: -1;
    font-size: 16px;
  }
}

.i-rating__caption {
  margin-bottom: 0;
  font-size: 14px;
  font-style: italic;
  color: colors.$gray-300;
}
