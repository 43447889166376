@use "./../../scss/settings/s-colors.scss" as colors;

@use "./../../scss/settings/s-general.scss" as general;

.feedback__wrapper {
  display: flex;
  align-items: center;
  gap: 0 6px;
}

.feedback {
  display: flex;
  align-items: center;
  gap: 0 12px;
  text-decoration: none;

  // &:hover {
  //   cursor: pointer;
  // }

  // path {
  //   transition: transform 0.2s;
  // }

  // &:hover,
  // &.--voted {

  //   .like {
  //     fill: colors.$primary;
  //   }
  // }
}

.feedback__title {
  color: colors.$primary;
}

.feedback__count {
  color: colors.$gray-300;
}
