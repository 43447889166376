@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.i-dev-project-slide {
  box-shadow: colors.$shadow-01;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(md) {

    .i-overview {
      display: none;
    }
  }
}

.i-dev-project-slide__image-block {
  position: relative;

  .i-overview {
    position: absolute;
    bottom: general.$spacing * 2;
    right: general.$spacing * 2;
  }
}

// Img

.i-dev-project-slide__image-link {
  display: block;

  .img,
  img {
    border-radius: 10px 10px 0 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.i-dev-project-slide__cta-button {
  position: absolute;
  bottom: 0;
  left: 16px;
  transform: translateY(50%);
}

.i-dev-project-slide__content {
  padding: 32px 16px 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;

  svg {
    margin-right: 8px;
  }

  path,
  rect {
    stroke: colors.$gray-300;
  }
}

.i-dev-project-slide__heading {
  margin-bottom: general.$spacing;
}

.i-dev-project-slide__heading-link {
  text-decoration-line: underline;
  text-decoration-color: transparent;
  text-decoration-style: solid;
  text-decoration-thickness: inherit;
  transition: text-decoration-color 0.2s;

  &:hover {
    text-decoration-color: colors.$primary;
  }
}

.i-dev-project-slide__position {
  display: flex;
  align-items: center;
  margin-bottom: general.$spacing * 3;
}

.i-dev-project-slide__perex {
  display: none;

  @include media-breakpoint-up(md) {
    display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.i-dev-project-slide__price {
  font-size: 21px;
  line-height: general.$spacing * 3;
  font-weight: bold;
  display: flex;
  gap: 9px;
  margin-top: auto;
}
