/* stylelint-disable no-descending-specificity */

.u-img-hover {
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);

  img {
    transition-property: opacity, transform;
    will-change: transform, opacity;
  }

  &:hover {

    img {
      transform: scale(1.03);
    }
  }
}

.u-img-opacity-hover {

  img,
  svg {
    transition: opacity 0.3s ease;
    transition-property: opacity;
    will-change: opacity;
  }

  &:hover {

    img,
    svg {
      opacity: 0.7;
    }
  }
}

/* stylelint-enable no-descending-specificity */
