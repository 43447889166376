@use "./../../../scss/settings/s-colors.scss" as colors;

.--re-agency-from-header {
  width: 100%;
  max-width: 100%;

  .base-header__heading {
    border-bottom: 1px solid colors.$gray;
    width: 100%;
    padding-bottom: 8px;
  }
}
