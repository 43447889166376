@use "settings/s-colors.scss" as colors;

.map-layout__map {

  .gm-style-iw {
    border: 0;
    padding: 0;
  }

  .gm-style-iw-d {
    overflow: hidden !important;
  }

  .gm-ui-hover-effect {
    position: relative;
    z-index: 5;
    background-color: #000 !important;
    border-radius: 50%;
    padding: 1px;
    top: 6px !important;
    right: 6px !important;
    width: 20px !important;
    height: 20px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;

    span {
      background-color: colors.$white !important;
      margin: 0 !important;
    }
  }
}

#map, #mapCanvas {
  width: 100%;
  position: relative;

  .map-legend {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    background-color: colors.$white;
    box-shadow: 0 3px 6px #00000017;
    margin-left: 10px;
  }

  .legend-item {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 16px;

    > span {

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    img {
      height: 24px;
    }
  }
}

.main-map__overlay {
  pointer-events: none;
  z-index: 0;
  width: 100%;
  padding: 16px;
  padding-left: 64px;
  position: absolute;
  bottom: 0;
  font-size: 18px;
  color: colors.$white;
  background:
    transparent linear-gradient(180deg, #2e3a4300 0%, #2e3a4384 35%, #2e3a43 100%)
    0% 0%
    no-repeat
    padding-box;

  @include media-breakpoint-up(lg) {
    padding-top: 24px;
    padding-bottom: 24px;
    font-size: 26px;
  }
}

.main-map__marker-content.--specified .main-map__marker-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-map__marker-content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &.--unspecified {
    max-width: 320px;
    padding: 32px 16px 16px;
  }

  &.--specified {
    width: 190px;
    height: 190px;
    padding: 8px;
    border: 3px solid colors.$primary;
    border-radius: 10px;
    overflow: hidden;

    &::before {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      width: 100%;
      height: 60%;
      background: linear-gradient(0deg, rgb(46 58 67 / 100%) 0%, rgb(46 58 67 / 52%) 66%, rgb(46 58 67 / 0%) 100%);
      z-index: 2;
    }
  }
}

.main-map__bottom-wrapper {
  display: flex;
  gap: 8px;
  position: relative;
  z-index: 3;
  margin: 0;
}

.main-map__marker__content-wrap {
  display: flex;
  gap: 16px;
}

.main-map__marker-title {
  color: colors.$white;
  position: relative;
  z-index: 3;
  margin-bottom: 4px;
  font-size: 16px;
  max-height: 40px;
  overflow: hidden;
}

.main-map__marker-info-unspecified {
  font-size: 16px;
  line-height: 1.6;
}

.main-map__marker-price {
  color: colors.$white;
  font-weight: 700;
  font-size: 16px;
}

.main-map__marker-link {
  color: colors.$conversion;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 4px;

  &::after {
    content: "";
    width: 12px;
    height: 12px;
    font-weight: 400;
    background-image: url("./images/svg/arrow-small-right-orange.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:hover {
    color: colors.$conversion;
    text-decoration: underline;
  }
}

.main-map__base-btn {
  font-weight: 700;
  font-size: 16px;
  display: block;
  margin-top: 8px;

  &.--text-right {
    text-align: right;
  }
}
