@use "./../../../scss/settings/s-colors.scss" as colors;

.realtor-info {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: center;
	column-gap: 46px;
	row-gap: 29px;

	.agency {
		display: flex;
		.img {
			max-height: 100%;
			img {
				max-height: 100%;
				@include media-breakpoint-down(sm) {
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}

	.realtor {
		display: flex;
		align-items: center;
		> .img {
			border-radius: 8px;
			max-height: 68px;
			margin-right: 25px;
			box-shadow: colors.$shadow-01;
		}
		h3, h4 {
			font-size: 21px;
			line-height: 27px;
		}
		h3 {
			font-weight: 400;
			font-size: 1rem;
			line-height: 1.2875rem;
		}
		.i-contact {
			margin-top: 3px;
		}
		.base-header {
			margin-bottom: 0;
		}
	}
	.agency {
		max-height: 68px;
		max-width: 250px;
	}
}