body[data-layout="user-section"] {

  > header {

    &.main-header.headroom.headroom--unpinned {

      ~ main {

        .user-section-menu {
          padding-top: 0 !important;
        }
      }
    }
  }

  > main {
    min-height: 100vh;
    padding-left: 296px;

    .user-section-main {
      padding: 19px 15px;

      @include media-breakpoint-up(md) {
        padding: 21px 28px;
      }

      > * > .base-header,
      > .base-header {
        margin-bottom: 25px;
      }
    }

    position: relative;

    .user-section-menu {
      width: 296px;
      //transition: padding-top 0.3s ease;
      position: fixed;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  @include media-breakpoint-down(md) {

    .user-section-menu {
      display: none;
    }

    > main {
      padding-left: 0;
    }
  }
}
