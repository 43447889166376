@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.s-posts-slide {
  overflow: hidden;
}

.s-posts-slide__container {
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  padding-bottom: 72px;

  @media (min-width: 1920px) {
    width: 1920px;
  }

  .swiper-wrapper {
    position: static;
  }
}

.s-posts-slide__slider {
  overflow: visible;
  max-width: none;
  margin: 0;
  position: static;
  margin-left: 16px;

  @include media-breakpoint-up(lg) {
    margin-left: 24px;
  }

  @include media-breakpoint-down(lg) {
    margin-right: 16px;
  }

  @media (min-width: 1920px) {
    margin-left: 0;
  }

  .slick-slide {
    height: auto;
    align-self: stretch;
  }
}

.s-posts-slide__bottom-wrapper {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 32px;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 auto;
  margin-top: general.$spacing * 3;
  transform: translateX(-50%);
  padding: 0 8px;

  @include media-breakpoint-up(sm) {
    width: 540px; //container width
  }

  @include media-breakpoint-up(md) {
    width: 720px; //container width
  }

  @include media-breakpoint-up(lg) {
    width: 864px; //container width
    padding: 0 16px;
  }

  @include media-breakpoint-up(xl) {
    width: 1140px; //container width
  }

  @include media-breakpoint-up(xxl) {
    width: 1358px; //container width
  }

  @include media-breakpoint-down(sm) {
    margin: 0;
    margin-top: general.$spacing * 3;
    width: 100vw;
  }

  @media (min-width: 1920px) {
    transform: translateX(-50%);
  }
}
