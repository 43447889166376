@use "./../../scss/settings/s-colors.scss" as colors;

.user-section-menu {
    background-color: colors.$gray-100;
    display: grid;
    height: 100vh;
    max-height: 100vh;
    grid-template-rows: auto 1fr auto;
    .top-buttons {
        padding: 12px 23px;
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        + .navs {
            > nav {
                &:first-child {
                    ul {
                        &:before {
                            content: unset;
                        }
                    }
                }
            }
        }
    }
    .navs {
        color: colors.$gray;
        overflow-y: scroll;
        > nav {
            .link {
                width: 100%;
                color: colors.$gray;
                padding: 12px 30px;
                grid-gap: 13px;
                .link__title {
                    width: 100%;
                }
                .icon {
                    min-height: 24px;
                    min-width: 24px;
                    max-height: 24px;
                    max-width: 24px;
                    svg {
                        min-height: 24px;
                        min-width: 24px;
                        max-height: 24px;
                        max-width: 24px;
                    }
                }
                text-decoration: none !important;
            }
            > ul {
                &:before {
                    content: '';
                    display: block;
                    height: 1px;
                    width: calc(100% - 2 * 12px);
                    background-color: colors.$gray-150;
                    margin-left: 12px;
                    margin-right: 12px;
                }
                li {
                    transition: all .2s ease-in-out;
                    &:hover {
                        background-color: colors.$gray-125;
                    }
                    &.active {
                        background-color: colors.$gray-125;
                        .link {
                            .link__title {
                                position: relative;
                                &:after {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: 50%;
                                    right: 0;
                                    border-radius: 50%;
                                    background-color: colors.$primary;
                                    height: 8px;
                                    width: 8px;
                                    transform: translateY(-50%);
                                }
                            }
                        }
                    }
                }
            }

            .nav-label {
                font-size: 14px;
                line-height: 1em;
                margin-bottom: 10px;
                padding-left: 23px;
                padding-right: 23px;
                margin-top: 27px;
                + ul {
                    > li {
                        &:first-child {
                            .link {
                                padding-top: 16px;
                            }
                        }
                    }
                }
            }
        }
        &:first-child {
            nav {
                &:first-child {
                    ul {
                        &:before {
                            content: unset;
                        }
                    }
                }
            }
        }

        &.bottom-fixed {
            > nav {
                > ul {
                    > li {
                        &:first-child {
                            .link {
                                padding-top: 16px;
                            }
                        }
                        &:last-child {
                            &:first-child {
                                .link {
                                    padding-top: 20px;
                                }
                            }
                            .link {
                                padding-bottom: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}
