@use "./../../../scss/settings/s-general.scss" as general;

@use "./../../../scss/settings/s-colors.scss" as colors;

.i-more-posts__heading {
  margin-bottom: general.$spacing * 5;
  color: colors.$gray-600;
}

.i-more-posts {
  //width: 474px;
  padding: general.$spacing * 9 general.$spacing * 10;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  text-decoration-color: transparent;

  @include media-breakpoint-down(lg) {
    width: 288px;
    padding: general.$spacing * 4 general.$spacing * 6;
    justify-content: flex-end;
    box-shadow: colors.$shadow-03;
    background-color: colors.$gray-100;
  }
}

.splide__slide.i-more-posts {
  height: 420px;
  max-width: 474px;

  @include media-breakpoint-down(lg) {
    height: 400px;
    max-width: 288px;
  }
}

.i-more-posts__img {
  margin-bottom: general.$spacing * 3;
  width: general.$spacing * 10;
  height: general.$spacing * 10;
}
