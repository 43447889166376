@use "./../../../scss/settings/s-colors.scss" as colors;

.s-category-nav__list {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.s-category-nav {
    padding: 16px 0;

    @include media-breakpoint-up(lg) {
        background-color: colors.$gray-100;
        padding: 0;
        margin-bottom: 32px;
    }
    + .base-section, + form .base-section {
        padding-top: 0;
        padding-bottom: 0;
        .base-section__container {
            margin-bottom: 3rem;
        }
    }
}

.s-category-nav__select {
    max-width: 375px;
    margin-inline: auto;

    @include media-breakpoint-up(lg) {
        display: none;
    }
}
