@use "./../../../scss/settings/s-colors.scss" as colors;

.g-social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px 0;
  position: relative;
  flex-wrap: wrap;

  &::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    top: 0;
    left: 0;
    background-color: colors.$gray-200;
  }

  &::after {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: colors.$gray-200;
  }

  @include media-breakpoint-down(md) {
    justify-content: flex-start;
    row-gap: 12px;
  }
}

.g-social-link__text {
  font-weight: 700;

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
