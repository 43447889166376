@use "./../../scss/settings/s-colors.scss" as colors;

@use "./../../scss/settings/s-general.scss" as general;

.splide__arrow {
  position: relative;
  width: general.$spacing * 6;
  height: general.$spacing * 6;
  background-color: colors.$primary;
  padding: 0;
  border-radius: 50%;
  border: none;
  left: auto;
  right: auto;
  transition: transform 0.2s, background-color 0.2s;

  &::after {
    display: none;
  }

  &:hover {
    background-color: colors.$primary-600;
  }

  .icon {
    margin-right: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  polyline {
    stroke: colors.$white;
  }

  svg {
    transition: transform 0.2s;
    height: general.$spacing * 2;
  }
}

.splide__arrows {
  display: flex;
  gap: 10px;
  align-items: center;

  &.--lg {

    .splide__arrow {
      width: general.$spacing * 10;
      height: general.$spacing * 10;
      box-shadow: 0 0 0 1px colors.$white;
      background-color: transparent;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        width: 100%;
        filter: blur(3px), brightness(15);
        background-color: colors.$white;
        opacity: 0.2;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }
    }
  }
}

.splide__arrow--next {

  svg {
    transform: translateX(2px);
  }

  &:hover {

    svg {
      transform: translateX(5px);
    }
  }
}

.splide__arrow--prev {

  svg { /* stylelint-disable-line no-descending-specificity */
    transform: translateX(-2px);
  }

  &:hover {

    svg {
      transform: translateX(-5px);
    }
  }
}
