@use "./../../../scss/settings/s-colors.scss" as colors;

.i-estate__image-link {
  display: block;
  height: 100%;
  width: 100%;

  img,
  .img {
    border-radius: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.i-estate__title-link {
  text-decoration-color: transparent;

  &:hover {
    text-decoration-color: colors.$primary;
    color: colors.$primary;
  }

  &:visited {
    color: colors.$primary;
  }
}

.i-estate__image {
  position: relative;
}

.i-estate__image-badges {
  position: absolute;
  top: 16px;
  left: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  max-height: 80%;
  flex-wrap: wrap;
  pointer-events: none;
}

.i-estate__content {
  position: relative;
  padding: 16px 28px 26px 34px;
  display: flex;
  flex-direction: column;
}

.i-estate__header-upload {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 4px;
  color: colors.$gray-300;
  font-style: italic;
  font-size: 16px;

  @include media-breakpoint-down(md) {
    justify-content: flex-end;
  }

  .icon {
    max-height: 1.2em;
    max-width: 1.2em;
    transform: translateY(-0.1em);

    svg {
      height: 1.5em;
      width: auto;
    }
  }
}

.i-estate__header-title {
  font-size: 26px;
  line-height: 1.3em;
  font-weight: 400;
  margin-bottom: 16px;

  @include media-breakpoint-down(sm) {
    font-size: 18px;
  }

  a,
  span {
    font-weight: inherit;
    font-size: inherit;
  }
}

.i-estate__description-text {
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  min-height: 24px * 4;
  margin-bottom: 0;

  @include media-breakpoint-down(lg) {
    -webkit-line-clamp: 3;
    min-height: 24px * 3;
  }

  @include media-breakpoint-down(sm) {
    -webkit-line-clamp: 2;
    min-height: 24px * 2;
  }
}

.i-estate__description {
  font-weight: 300;
  font-size: 16px;
  overflow: hidden;
  mask-image: linear-gradient(180deg, #000 48px, transparent);

  @include media-breakpoint-down(sm) {
    mask-image: linear-gradient(180deg, #000 10px, transparent);
  }
}

.i-estate__cta-button {
  position: absolute;
  bottom: 24px;
  left: 0;
  transform: translateX(-50%);

  @include media-breakpoint-down(md) {
    bottom: 100%;
    left: 16px;
    transform: translate(0, 50%);
  }
}

.i-estate__image-icons {
  position: absolute;
  bottom: 24px;
  right: 32px;

  @include media-breakpoint-down(sm) {
    bottom: 8px;
    right: 8px;
  }
}

.i-estate__footer {
  display: flex;
  gap: 32px;
  align-items: center;
  margin: auto 0 0;
}

.i-estate__footer-price {
  flex-grow: 1;

  @include media-breakpoint-up(lg) {
    padding-left: 16px;
  }
}

.i-estate__footer-price-value {
  font-size: 24px;
  font-weight: 700;
  white-space: nowrap;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

.i-estate__footer-logo {
  //max-width: 100%;
  max-width: 150px;
  max-height: 50px;
  display: flex;
  justify-content: flex-end;

  @include media-breakpoint-down(xxl) {
    display: none;
  }

  picture {
    display: flex;
    max-height: 100%;

    img {
      object-fit: contain;
      object-position: right;
    }
  }
}

.i-estate__footer-button {

  .btn {
    min-width: 160px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.i-estate__dev-project-images {
  position: relative;
  display: flex;
  grid-column-start: 1;
  grid-column-end: 3;
  height: auto;
  gap: 16px;
  padding: 16px;
  background-color: colors.$gray-100;
  overflow: hidden;

  .img {
    width: 152px;
    height: 152px;
    flex-shrink: 0;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.i-estate {
  position: relative;
  display: grid;
  grid-template-columns: 320px 1fr;
  box-shadow: colors.$shadow-01;
  border-radius: 10px;
  overflow: hidden;
  transition: box-shadow 300ms ease;

  &.--map {
    grid-template-columns: 216px 1fr;

    .i-estate__footer {
      gap: 8px;
    }

    .i-estate__footer-price {
      padding: 0;
    }

    .i-estate__footer-price-value {
      font-size: 18px;
    }

    .i-estate__footer-button .btn {
      padding: 8px;
    }

    .i-estate__content {
      padding: 16px;
    }

    .i-estate__cta-button {
      display: none;
    }

    .i-estate__header-title {
      font-size: 16px;
      margin-bottom: 0;
    }

    .i-estate__description-text {
      -webkit-line-clamp: 2;
      min-height: 24px * 2;
    }

    .i-estate__description {
      mask-image: linear-gradient(180deg, #000 10px, transparent);
    }

    .i-estate__dev-project-images {

      .img {
        width: 80px;
        height: 80px;
      }
    }

    .i-estate__footer-button {
      display: none;
    }
  }

  &.--top,
  &.--dev-project {
    border: 2px solid colors.$primary;
  }

  &.--sold {
    filter: grayscale(1);
  }

  &:hover {
    box-shadow: colors.$shadow-04;
  }

  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    max-width: 344px;
    margin-inline: auto;
  }
}
