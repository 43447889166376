@use "./../../../scss/settings/s-general.scss" as general;

.g-real-estate {
  display: grid;
  gap: 40px 100px;
  align-items: center;
  justify-items: center;
  grid-auto-rows: 112px;
  grid-template-columns: repeat(3, auto);

  @include media-breakpoint-down(xl) {
    gap: 32px 50px;
    grid-auto-rows: 88px;
    grid-template-columns: repeat(3, auto);
  }

  @include media-breakpoint-down(lg) {
    gap: 24px 50px;
    grid-auto-rows: 88px;
    grid-template-columns: repeat(2, auto);
  }

  @include media-breakpoint-down(md) {
    gap: 32px 50px;
    grid-auto-rows: 88px;
    grid-template-columns: repeat(3, auto);
    margin-top: general.$spacing * 4;
  }

  @include media-breakpoint-down(sm) {
    gap: 16px 0;
    grid-auto-rows: 112px;
    grid-template-columns: 100%;
  }
}
