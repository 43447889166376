$columnGap: 8px;
$rowGap: 8px;

.g-base {
  display: flex;
  flex-wrap: wrap;
  column-gap: $columnGap;
  row-gap: $rowGap;

  &.--column {
    flex-direction: column;
  }

  &.--align-between {
    justify-content: space-between;
  }
}
