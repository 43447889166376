.s-estate-content__wrapper {
  display: flex;
  gap: 176px;

  @include media-breakpoint-down(xxl) {
    gap: 124px;
  }

  @include media-breakpoint-down(xl) {
    gap: 74px;
  }

  @include media-breakpoint-down(lg) {
    gap: 32px;
  }

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }

  .entry-content {

    p {

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.s-estate-content__ads {
  width: 252px;
  flex-shrink: 0;

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
