@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.i-filter-table-empty-note {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 213px;
  height: 213px;
  padding: 20px;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
  transform: translate(-50%, -50%);
  background-color: colors.$white;
  box-shadow: colors.$shadow-03;
  gap: general.$spacing * 2;
}

.i-filter-table-empty-note__text {
  font-size: 18px;
}
