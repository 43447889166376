@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.s-broker-detail {
  position: relative;

  .g-contact {
    display: grid;
    margin-top: general.$spacing * 4;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.s-broker-detail__content {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-up(lg) {
    grid-template-columns: 208px 1fr;
  }

  @include media-breakpoint-only(md) {
    grid-template-columns: 160px 1fr;
    gap: 24px;
  }

  @include media-breakpoint-up(md) {
    display: grid;
    gap: 24px;
    align-items: flex-start;
  }
}

.s-broker-detail_header {
  margin-bottom: general.$spacing * 6;
}

.s-broker-detail__heading {
  margin-bottom: general.$spacing;

  @include media-breakpoint-down(md) {
    text-align: center;
  }
}

.s-broker-detail__rating {
  margin-bottom: general.$spacing * 4;

  @include media-breakpoint-down(md) {
    display: flex;
    justify-content: center;
  }
}

.s-broker-detail__bottom-content {
  display: flex;

  @include media-breakpoint-down(xl) {
    flex-direction: column;
  }
}

.s-broker-detail__buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.s-broker-detail__cta {
  display: flex;
  gap: 16px;

  @include media-breakpoint-down(xxl) {
    order: -1;
    width: 100%;
  }
}

.s-broker-detail__images {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-down(md) {
    margin-bottom: general.$spacing * 4;
  }
}

.s-broker-detail__broker-img {
  margin-bottom: general.$spacing * 3;
  width: 208px;
  max-width: 100%;
}

.s-broker-detail__re-angency-img {
  max-width: 170px;
}

.s-broker-detail__chart,
.s-broker-detail__contacts {
  flex-basis: 50%;
}

.s-broker-detail__chart {

  @include media-breakpoint-down(xl) {
    margin-top: general.$spacing * 4;
  }
}

.s-broker-detail__decor-img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 667px;
  height: 864px;
  transform: translate(-1230px, -38%);
  z-index: -1;
}
