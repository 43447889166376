@use "./../../../scss/settings/s-colors.scss" as colors;

$paddingX: 8px;
$paddingY: 0;
$fontSize: 16px;
$fontWeight: 400;
$color: colors.$black;
$colorRequired: colors.$danger;

.f-label {
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $color;
  margin-bottom: 0;
  padding: $paddingY $paddingX;
  display: flex;
  align-items: center;

  &.--required {

    &::after {
      content: "*";
      color: $colorRequired;
      padding-left: 4px;
    }
  }
}
