@use "settings/s-colors.scss" as colors;

.main-map-filtration {

    .g-main-filter__heading {
        font-size: 21px;
    }

    .f-option-switch {
        margin-bottom: 0;
    }

    .box__content {
        padding-left: 16px;
        padding-right: 16px;
    }

    .f-range__input {
        gap: 2px;
    }

    .g-main-filter-extended {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 0;
        row-gap: 16px;

        .g-main-filter-extended__cell {
            grid-row: span 1 / auto;
        }
    }
}

.main-map-filtration__location-box {

    .f-label,
    .link {
        font-weight: bold;
    }
}

.main-map-filtration__body {
    padding: 8px 16px 32px;
}

.main-map-filtration__bar {
    display: flex;
    padding: 16px 16px 8px;
    box-shadow: 0 3px 4px #00000029;
    background-color: colors.$white;
    gap: 16px;
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.main-map-filtration__close-button {
    display: flex;
    gap: 8px;
    color: colors.$primary;
    font-size: 18px;
    align-items: center;
    cursor: pointer;

    svg {
        height: 18px;
        order: -1;
    }
}

.main-map-filtration__count {
    line-height: 20px;
    display: flex;
    align-items: center;
    margin-left: auto;
    text-align: right;
}
