.s-filter-table {
  padding-top: 136px;
  overflow: hidden;
}

.s-filter-table__content-group {
  display: flex;
  align-items: flex-start;
  gap: 40px;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }
}
