@use "settings/s-general.scss" as general;

@use "settings/s-colors.scss" as colors;

$padding-x: 16px;
$padding-x-sm: 12px;
$padding-y: 7px;
$borderRadius: general.$border-base;
$fontSize: 15px;
$background: colors.$white;
$color: colors.$black;
$colorPlaceholder: colors.$gray-300;
$colorInvalid: colors.$danger;
$borderColor: colors.$gray-200;
$borderColorFocus: colors.$primary-200;
$textAreaMinHeight: 115px;

.f-range__input {
  color: $color;
  background-color: $background;
  border: 1px solid $borderColor;
  transition: border-color 0.3s ease;
  padding: $padding-y $padding-x;
  border-radius: $borderRadius;
  font-size: $fontSize;
  display: flex;
  position: relative;
  column-gap: 8px;

  @include media-breakpoint-down(sm) {
    padding: $padding-y $padding-x-sm;
    column-gap: 4px;
  }

  &::before {
    content: attr(data-prefix);
    flex-shrink: 0;
  }

  &::after {
    content: attr(data-suffix);
    flex-shrink: 0;
  }

  input {
    color: $color;
    border: 0;
    display: block;
    border-radius: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    background-color: transparent;
    text-align: right;

    &:focus {
      outline: none;
    }
  }

  &.--no-suffix {

    &::after {
      display: none;
    }
  }
}

.f-range__inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.f-range {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .f-label {
    font-weight: bold;
  }

  .noUi-horizontal {
    height: 33px;
    border-radius: 0;
    box-shadow: none;
    border: none;
  }

  .noUi-target {
    background-color: transparent;
  }

  .noUi-connect {
    background: colors.$primary;
    border-radius: 0;
  }

  .noUi-connects {
    background-color: colors.$gray-200;
    overflow: visible;

    &::after,
    &::before {
      content: "";
      position: absolute;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      background-color: colors.$gray-200;
      width: 9px;
      height: 9px;
      border-radius: 50%;
    }

    &::after {
      right: 0;
    }

    &::before {
      left: 0;
    }
  }

  .noUi-base,
  .noUi-connects {
    height: 3px;
  }

  .noUi-state-drag {
    cursor: grabbing;
  }

  .noUi-handle {
    background: transparent;
    width: 33px;
    height: 33px;
    border: none;
    border-radius: none;
    box-shadow: none;
    top: -16px;
    bottom: auto;
    cursor: grab;

    &::after {
      content: "";
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      width: 24px;
      height: 24px;
      border-radius: 8px;
      background-color: colors.$primary;
      box-shadow: inset 0 3px 6px #00000029, 0 1px 1px #2e3a4340;
    }

    &::before {
      content: "";
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: colors.$white;
      box-shadow: 0 1px 1px #0000005a;
      z-index: 2;
    }
  }
}

.f-range__slider {
  transform: translateY(50%);
  padding: 0 16px;
}
