@use "./../../../scss/settings/s-general.scss" as general;

@use "./../../../scss/settings/s-colors.scss" as colors;

.checkbox {
  position: relative;

  input {
    height: 0;
    width: 0;
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  label {
    position: relative;
    padding-left: 32px;
    font-size: 16px;
    padding-right: 0;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      border-style: solid;
      border-width: 2px;
      border-radius: 5px;
      border-color: colors.$primary;
      background-color: colors.$white;
      transition: background-color 0.2s;
    }

    &::after {
      content: "";
      position: absolute;
      top: 5px;
      left: 9px;
      height: 12px;
      width: 6px;
      transition: opacity 0.2s;
      opacity: 0;
      border-style: solid;
      border-width: 0  2px 2px 0;
      border-color: colors.$white;
      transform: rotate(45deg);
    }

    &:hover {
      cursor: pointer;

      &::before {
        background-color: colors.$gray-100;
      }
    }
  }

  input:checked + label {

    &::after {
      opacity: 1;
    }

    &::before {
      background-color: colors.$primary;
    }

    &:hover {

      &::before {
        background-color: colors.$primary-600;
      }
    }
  }
}
