@use "settings/s-colors" as colors;

.i-selected-filter {
  --color: #{colors.$gray};

  color: var(--color);
  min-height: 32px;
  padding-block: 2px;
  padding-inline: 12px;
  border-radius: 8px;
  border: 2px solid var(--color);
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: colors.$gray-100;
  }
}

.i-selected-filter__close {
  width: 8px;
  height: 8px;

  svg {
    width: 100%;
    height: 100%;
  }
}
