@use "./../../../scss/settings/s-general.scss" as general;

@use "./../../../scss/settings/s-colors.scss" as colors;

.i-post-slide__content-col {
  padding: general.$spacing * 5;
  padding-bottom: general.$spacing * 3;
  flex-grow: 2;

  @include media-breakpoint-down(lg) {
    padding: general.$spacing * 5 general.$spacing * 3;
    padding-bottom: general.$spacing * 4;
  }
}

.i-post-slide__content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.i-post-slide__perex {
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: general.$spacing * 4;
  line-height: 24px;

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.i-post-slide__image-col {
  width: 272px;
  flex-shrink: 0;
  position: relative;

  @include media-breakpoint-down(lg) {
    width: 288px;
  }
}

.i-post-slide__cta-button {
  position: absolute;
  bottom: 24px;
  right: 0;
  transform: translateX(50%);

  @include media-breakpoint-down(lg) {
    right: auto;
    left: 43px;
    bottom: 0;
    transform: translateY(50%);
  }
}

.i-post-slide__heading-link {
  font-weight: bold;
  text-decoration-color: transparent;
  margin-bottom: general.$spacing * 2;
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &:hover {
    color: colors.$black;
    text-decoration-color: colors.$black;
  }

  @include media-breakpoint-down(lg) {
    -webkit-line-clamp: 3;
  }
}

.i-post-slide__origin {
  display: flex;
  color: colors.$gray-300;
  gap: 27px;
  margin-bottom: general.$spacing * 3;
  line-height: 16px;

  @include media-breakpoint-down(lg) {
    -webkit-line-clamp: 3;
    margin-bottom: general.$spacing * 2;
  }
}

.i-post-slide__footer {
  margin-top: auto;
}

.i-post-slide {
  display: flex;
  flex-direction: flex-start;
  background-color: colors.$gray-100;
  border-radius: 10px;
  width: 782px !important;

  &.--full {
    width: 100% !important;

    .i-post-slide__image-col {
      width: 436px;
      max-width: 100%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .i-post-slide__perex {
      max-height: 96px;
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }

  &.--compact {
    width: 554px !important;

    .i-post-slide__image-col {
      width: 200px;
    }

    .i-post-slide__perex {
      display: none;
    }

    .i-post-slide__heading-link {
      -webkit-line-clamp: 3;
    }

    .i-post-slide__content-col {
      padding: general.$spacing * 5;
      padding-right: general.$spacing * 2;
      padding-bottom: general.$spacing * 3;
    }

    .i-post-slide__origin {
      display: none;
    }

    @include media-breakpoint-down(lg) {
      width: 288px;
      max-width: 288px;

      .i-post-slide__image-col {
        width: 100%;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    box-shadow: colors.$shadow-03;
    flex-direction: column;
    height: 100%;
    max-width: 288px;
  }
}

.i-post-slide-image-link {
  display: block;
}

.i-post-slide.--inline {
  flex-direction: column;
  max-width: 100%;

  .i-post-slide__image-col {
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .i-post-slide__image {
    width: 100%;
  }

  .i-post-slide__cta-button {
    top: 100%;
    left: 16px;
    bottom: auto;
    right: auto;
    transform: translate(0, -50%);
  }
}
