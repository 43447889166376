@use "./../../../scss/settings/s-colors.scss" as colors;
@use "./../field-base.scss" as field_base;

.f-search {
    --autocomplete-width: 100%;

    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    position: relative;

    .f-wrap {
        flex-grow: 1;
        height: 100%;
        margin-right: -12px;
        width: calc(100% + 12px);
    }

    .f-base {
        height: 48px;
        display: flex;
        align-items: center;
    }

    .js-autocomplete {
        width: var(--autocomplete-width);
    }

    &.--without-button {
        .js-autocomplete {
            width: 100% !important;
        }
    }
}

.f-search__button {
    flex-shrink: 0;
    height: 100%;

    @include media-breakpoint-down(sm) {

        .btn {
            width: 48px;
            height: 48px;
        }

        .btn__title {
            display: none;
        }
    }
}

.f-wrap {
    &.--highlited {
        .f-search {
            .f-base {
                border: 2px solid colors.$conversion;
                padding: field_base.$paddingY field_base.$paddingX;
                background-color: field_base.$background;
                height: unset;
            }
        }
    }
}
