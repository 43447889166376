@use "./../../scss/settings/s-colors.scss" as colors;

@use "./../../scss/settings/s-general.scss" as general;

.swiper-arrows__arrow {
  position: relative;
  width: general.$spacing * 6;
  height: general.$spacing * 6;
  background-color: colors.$primary;
  border-radius: 50%;
  left: auto;
  right: auto;
  transition: transform 0.3s ease, background-color 0.3s ease, opacity 0.3s ease;
  user-select: none;

  &::after {
    display: none;
  }

  &:hover {
    background-color: colors.$primary-600;
  }

  polyline {
    stroke: colors.$white;
  }

  svg {
    transition: transform 0.3s ease;
    height: general.$spacing * 2;
  }
}
/* stylelint-disable no-descending-specificity */

.swiper-arrows {
  gap: 10px;
  display: flex;
  align-items: center;

  &.--lg {

    .swiper-arrows__arrow {
      width: general.$spacing * 10;
      height: general.$spacing * 10;
      box-shadow: 0 0 0 1px colors.$white;
      background: #ffffff80 0% 0% no-repeat padding-box;
      backdrop-filter: blur(3px);

      svg {
        width: 16px;
        height: auto;
      }
    }
  }
}

.swiper-button-next {

  svg {
    transform: translateX(2px);
  }

  &:hover {

    svg {
      transform: translateX(5px);
    }
  }
}

.swiper-button-prev {

  svg {
    transform: translateX(-2px);
  }

  &:hover {

    svg {
      transform: translateX(-5px);
    }
  }
}
/* stylelint-enable no-descending-specificity */
