@use "./../../../scss/settings/s-general.scss" as general;

@use "./../../../scss/settings/s-colors.scss" as colors;

.f-radio {
  position: relative;

  input {
    height: 0;
    width: 0;
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  label {
    position: relative;
    padding-left: 36px;
    font-size: 16px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      border-radius: 100%;
      border-style: solid;
      border-width: 2px;
      border-color: colors.$primary;
      background-color: colors.$white;
      transition: background-color 0.2s, border-color 0.2s;
    }

    &::after {
      content: "";
      position: absolute;
      top: 4px;
      left: 4px;
      height: 16px;
      width: 16px;
      border-radius: 100%;
      background-color: colors.$primary;
      transition: opacity 0.2s, background-color 0.2s;
      opacity: 0;
    }

    &:hover {
      cursor: pointer;

      &::before {
        background-color: colors.$gray-100;
      }
    }
  }

  input:checked + label {

    &::after {
      opacity: 1;
    }

    &::before {
      border-color: colors.$gray-200;
    }

    &:hover {

      &::before {
        background-color: colors.$white;
      }

      &::after {
        background-color: colors.$primary-600;
      }
    }
  }
}
