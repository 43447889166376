@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.i-broker-review {
  box-shadow: colors.$shadow-01;
  border-radius: 10px;
  width: 536px;
  max-width: calc(100vw - 38px);
  padding: general.$spacing * 3;
  display: flex;
  flex-direction: column;
  gap: general.$spacing * 3;
}

.i-broker-review__header {
  display: flex;
  align-items: flex-start;
  gap: 19px;

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }
}

.i-broker-review__img {
  width: general.$spacing * 6;
  box-shadow: colors.$shadow-02;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.i-broker-review__heading-block {
  flex-grow: 2;
  flex-shrink: 0;

  @include media-breakpoint-down(md) {
    width: calc(100% - 67px);
  }
}

.i-broker-review__meta {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: end;
  font-style: italic;
  color: colors.$gray-300;

  @include media-breakpoint-down(md) {
    width: 100%;
    text-align: start;
  }
}

.i-broker-review__perex {
  margin-bottom: 0;
}
