@use "./../../../scss/settings/s-colors.scss" as colors;

.i-filter-table-header {
  display: flex;
  align-items: flex-end;
  padding: 7px 8.5px;
  color: colors.$gray;
  width: 100%;
}

.i-filter-table-header__title {
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-size: 24px;
  color: colors.$black;
}

.i-filter-table-header__title-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: colors.$gray-100;
  border-radius: 50%;
  margin-right: 10px;
}

.i-filter-table-header__counter {
  width: 100px;
  text-align: right;
  flex-shrink: 0;
  font-size: 14px;

  @include media-breakpoint-down(xl) {
    display: none;
  }
}

.i-filter-table-header__notification {
  width: 112px;
  flex-shrink: 0;
  text-align: center;
  font-size: 14px;
}

.i-filter-table-header__badge {
  display: block;
  width: 100px;
  margin-left: 21px;
  min-height: 1px;
}

.i-filter-table-header__actions {
  width: 205px;
  flex-shrink: 0;
}
