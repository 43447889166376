@use "./../../scss/settings/s-colors.scss" as colors;

.footer-social {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 16px;
  font-weight: 700;
  flex-shrink: 0;
  transition: text-decoration-color 0.3s ease;
  transition-property: text-decoration-color, color;
  text-decoration: underline;
  color: colors.$gray-200;
  text-decoration-color: transparent;

  path,
  circle {
    stroke: colors.$white;
  }

  &:hover {
    color: colors.$white;
    text-decoration-color: colors.$white;
  }
}
