@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.i-review-chart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 21px;

  .link__title {
    font-weight: 400;
  }

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    flex-shrink: 3;
  }

  @include media-breakpoint-down(md) {

    .link__title {
      font-size: 18px;
      margin-top: general.$spacing;
    }
  }
}

.i-review-chart__heading {
  font-weight: bold;
  margin-bottom: general.$spacing;

  @include media-breakpoint-down(md) {
    margin-bottom: 0;
  }
}

.i-review-chart__chart {
  width: 160px;
  aspect-ratio: 1/1;
  display: block;
  position: relative;
  pointer-events: none;

  @include media-breakpoint-down(xl) {
    width: 136px;
    flex-shrink: 3;
  }

  @include media-breakpoint-down(sm) {
    width: 88px;
  }
}

.i-review-chart__legend {
  flex-shrink: 0;

  @include media-breakpoint-down(md) {
    text-align: center;
  }
}

.i-review-chart__canvas,
.i-review-chart__canvas-assistant {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.i-review-chart__total {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.i-review-chart__percent {
  color: colors.$gray-600;
  flex-shrink: 0;

  &.i-review-chart__percent {

    @include media-breakpoint-down(md) {
      font-size: 22px;
    }
  }
}

.i-review-chart__total-count {
  color: colors.$gray-300;
  flex-shrink: 0;
  display: block;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.i-review-chart__legend-total-count {
  color: colors.$gray-300;
  display: none;

  @include media-breakpoint-down(md) {
    display: block;
  }
}
