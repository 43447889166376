@use "./../../../scss/settings/s-colors.scss" as colors;

.i-social-link {
  display: table;
  padding: 6px 12px;
  border: 1px solid colors.$primary-200;
  border-radius: 8px;
  text-decoration: none;
  position: relative;
  transition: color 250ms ease-in-out;

  &::before {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    left: 50%;
    top: 0;
    background-color: colors.$primary-200;
    transition: width 250ms ease-in-out;
    transform: translateX(-50%);
    border-radius: 8px;
  }

  &:hover::before {
    width: calc(100% + 2px);
  }

  path {
    transition: stroke 250ms ease-in-out;
  }

  &:hover {
    color: colors.$white;

    path {
      stroke: colors.$white;
    }
  }
}

.i-social-link__wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  z-index: 2;
}
