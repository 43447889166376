.g-re-agency-form {
  max-width: 440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    width: 100%;
  }

  .f-wrap {
    margin-bottom: 20px;
  }

  .input__wrapper {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .f-base {
    font-style: italic;
  }

  .btn {
    margin: 0 auto;
    display: table;
    box-shadow: 0 10px 20px #e8dfcd;
  }
}

.g-re-agency-form__gdpr {
  text-align: center;
  margin-bottom: 20px;

  @include media-breakpoint-down(md) {
    max-width: 204px;
    margin: 0 auto 20px;
  }
}
