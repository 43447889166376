@use "./../../../scss/settings/s-colors.scss" as colors;

.i-filter-table {
  position: relative;
  display: flex;
  align-items: center;
  border-top: 1px solid colors.$gray-150;
  padding: 7px 8.5px;

  &:hover {
    background-color: colors.$gray-100;
  }

  @include media-breakpoint-down(xl) {
    flex-wrap: wrap;
    padding: 10px 100px 10px 8.5px;
  }
}

.i-filter-table__title {
  flex-grow: 1;
  font-size: 18px;
  line-height: 24px;

  @include media-breakpoint-down(xl) {
    width: 100%;
    margin-bottom: 4px;
  }
}

.i-filter-table__title-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.i-filter-table__counter {
  display: flex;
  justify-content: flex-end;
  width: 100px;
  flex-shrink: 0;
  text-align: right;

  @include media-breakpoint-down(xl) {
    display: flex;
    align-items: flex-end;
    width: auto;
  }
}

.i-filter-table__counter-span {
  flex-shrink: 0;
  justify-content: flex-end;
  display: flex;
  align-items: flex-end;

  @include media-breakpoint-down(xl) {
    width: auto;
  }
}

.i-filter-table__counter-description {
  display: none;
  font-size: 14px;
  color: colors.$gray-200;
  margin-left: 0.5em;

  @include media-breakpoint-down(xl) {
    display: block;
  }
}

.i-filter-table__badge {
  width: 128px;
  flex-shrink: 0;
}

.i-filter-table__badge-span {
  display: inline-block;
  background-color: colors.$danger;
  color: colors.$white;
  line-height: 24px;
  padding: 0 10px;
  border-radius: 24px;
  margin-left: 16px;
  white-space: nowrap;

  @include media-breakpoint-down(xl) {
    margin-left: 8px;
  }
}

.i-filter-table__button {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  width: 112px;
  padding: 0 24px;

  @include media-breakpoint-down(xl) {
    position: absolute;
    right: 8px;
    top: 8px;
    padding: 0;
  }
}

.temp-history-btn {
  display: block;
  width: 48px;
  height: 48px;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 10%);
  border: none;
  background-color: transparent;
  border-radius: 50%;
}

.i-filter-table-actions {
  display: flex;
  width: 205px;
  white-space: nowrap;
  flex-shrink: 0;
}

.i-filter-table__modal-content {
  padding: 29px 25px 24px;
}
