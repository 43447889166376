@use "settings/s-colors.scss" as colors;

.main-filter-toggle__body {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.3s ease;

    &.--active {
        grid-template-rows: 1fr;
    }
}

.main-filter-toggle__content {
    overflow: hidden;
    position: relative;
}

.main-filter-toggle__icon {
    transition: transform 0.3s ease;
    transform: scaleY(1);
}

.main-filter-toggle__title {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    color: colors.$primary;

    &.--active {

        .main-filter-toggle__icon {
            transform: scaleY(-1);
        }
    }
}

.main-filter-toggle__header {
    display: flex;
    justify-content: flex-end;
    position: relative;

    @include media-breakpoint-down(md) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
    }
}

.main-filter-toggle {
    display: flex;
    flex-direction: column;
}

.main-filter-toggle__button {

    @include media-breakpoint-up(md) {
        &:not(:first-child:last-child) {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
