@use "./../../../scss/settings/s-colors.scss" as colors;

.s-re-agents {

  .f-search__button {

    .btn {
      height: 100%;
    }
  }

  .f-search {
    max-width: 550px;
    margin-bottom: 65px;

    @include media-breakpoint-down(md) {
      margin-bottom: 24px;
    }
  }
}

.s-re-agents__dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;

  @include media-breakpoint-down(md) {
    gap: 8px;
  }
}
