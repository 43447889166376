@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.i-estate-detail-intro__img {
  display: block;
  border-radius: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  aspect-ratio: 3/2;

  .img img {
    border-radius: 0;
    object-fit: contain;
    width: 100%;
    height: 100%;
    aspect-ratio: 3/2;
  }

  // background with blur
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .img {
    backdrop-filter: blur(12px);
  }

  @media screen and (min-width: 1200px) {
    // centered pics

    .img {
      width: 100%;
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: stretch;

      img {
        max-height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &.u-img-hover {

      &:hover {

        img {
          transform: translate(-50%, -50%) scale(1.07);
        }
      }
    }
  }

  @media screen and (max-width: 1199px) {

    .img {

      picture {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
