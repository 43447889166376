@use "./../../../scss/settings/s-general.scss" as general;

@use "./../../../scss/settings/s-colors.scss" as colors;

.s-highlited-broker {
  position: relative;
  overflow: hidden;
}

.s-highlited-broker__wrapper {
  display: grid;
  gap: 48px 0;
  grid-template-columns: repeat(12, 1fr);
  position: relative;
}

.s-highlited-broker__side-content {
  grid-column: span 4 / auto;
  padding: 0 80px;
  position: relative;

  @include media-breakpoint-down(xxl) {
    grid-column: span 12/ auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    padding: 0;
    position: relative;
  }

  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.s-highlited-broker__rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;

  @include media-breakpoint-down(sm) {
    margin-bottom: general.$spacing * 5;
  }
}

.s-highlited-broker__rating-text {
  text-align: center;
  margin-bottom: general.$spacing * 4;
}

.s-highlited-broker__decor-img {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 365px;
  width: 533px;
  transform: translate(38%, 35px);

  picture {
    width: 100%;
    height: 100%;
  }

  @include media-breakpoint-down(xxl) {
    position: relative;
    bottom: auto;
    right: auto;
    height: auto;
    width: 100%;
    transform: translate(0, 0);
  }
}

.s-highlited-broker__decor-square {
  position: absolute;
  bottom: 0;
  right: 50%;
  height: 957px;
  width: 957px;
  transform: translate(calc(1500px), 50%);
  z-index: -1;

  @include media-breakpoint-down(xxl) {
    right: 0%;
    transform: translate(calc(100% - 340px), 25%);
  }

  @include media-breakpoint-down(xl) {
    transform: translate(calc(100% - 240px), 35%);
  }

  @include media-breakpoint-down(md) {
    transform: translate(calc(100% - 220px), 38%);
    height: 507px;
    width: 507px;
  }

  @include media-breakpoint-down(sm) {
    transform: translate(calc(100% - 200px), 20%);
  }
}
