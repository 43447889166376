@use "settings/s-colors" as colors;

.n-user__item-icon {
  width: 16px;
  height: 16px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.n-user__item {
  --color: #{colors.$white};

  color: var(--color);
  text-decoration-color: transparent;

  &.--disabled {
    --color: #{colors.$gray};

    .n-user__item-icon {
      opacity: 0.5;
    }
  }
}

a.n-user__item {

  &:hover {
    color: var(--color);
    text-decoration-color: var(--color);
  }
}
