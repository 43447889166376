@use "./../../../scss/settings/s-colors.scss" as colors;

@use "sass:color";

.i-estate-icons {
  display: flex;
  flex-direction: column;
  background-color: color.adjust(colors.$black, $alpha: -0.25);
  padding: 16px;
  border-radius: 24px;
  align-items: flex-start;
}

.i-estate-icons__photocount {
  display: flex;
  color: #fff;
  gap: 8px;
}

.i-estate-icons__props {
  display: flex;
  gap: 8px;
}

.i-estate-icons__box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.i-estate-icons__props .i-estate-icons__box {
  margin-bottom: 8px;
}
