@use "./../../../scss/settings/s-colors.scss" as colors;

.i-filter-table-edit-area {
  position: relative;
  padding: 2rem;
  background-color: colors.$gray-100;

  &::after {
    content: " ";
    display: block;
    position: absolute;
    bottom: 100%;
    right: 120px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 16px 16px;
    border-color: transparent transparent #f4f7fa;
  }
}

.i-filter-table-edit-area.js-edit-active {
  display: flex;
  animation: scale-up 250ms ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes scale-up {

  0% {
    transform: translateY(30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
