@use "settings/s-general.scss" as general;

.img {
  overflow: hidden;

  &.--ratio-4-3 {
    aspect-ratio: 4/3;
  }

  &.--ratio-3-4 {
    aspect-ratio: 3/4;
  }

  &.--ratio-16-9 {
    aspect-ratio: 19/9;
  }

  &.--ratio-9-16 {
    aspect-ratio: 68/105;
  }

  &.--ratio-3-2 {
    aspect-ratio: 3/2;
  }

  &.--ratio-1-1 {
    aspect-ratio: 1/1;
  }

  &.--ratio-lg-4-3 {

    @include media-breakpoint-down(lg) {
      aspect-ratio: 4/3;
    }
  }

  &.--ratio-lg-3-4 {

    @include media-breakpoint-down(lg) {
      aspect-ratio: 3/4;
    }
  }

  &.--ratio-lg-16-9 {

    @include media-breakpoint-down(lg) {
      aspect-ratio: 9/5;
    }
  }

  &.--ratio-lg-9-16 {

    @include media-breakpoint-down(lg) {
      aspect-ratio: 68/105;
    }
  }

  &.--ratio-lg-3-2 {

    @include media-breakpoint-down(lg) {
      aspect-ratio: 3/2;
    }
  }

  &.--ratio-lg-1-1 {

    @include media-breakpoint-down(lg) {
      aspect-ratio: 1/1;
    }
  }

  img {
    object-fit: cover;
  }

  &.--contain {

    picture {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
