@use "./../../../scss/settings/s-general.scss" as general;

@use "./../../../scss/settings/s-colors.scss" as colors;

.radio-fieldset__wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.radio-fieldset__heading {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
}

.radio-fieldset__list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  row-gap: 8px;

  &.--cols-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
