@use "settings/s-colors" as colors;

.b-login-register {
  border-radius: 8px;
  margin-inline: auto;
  overflow: hidden;
  max-width: 392px;

  @include media-breakpoint-up(lg) {
    box-shadow: 0 15px 26px #00000029;
    display: grid;
    grid-template-columns: 335px 1fr;
    max-width: 824px;
  }
}

.b-login-register__aside {
  background-color: colors.$gray-100;

  @include media-breakpoint-up(lg) {
    padding-left: 40px;
    padding-right: 36px;
    padding-top: 48px;
    padding-bottom: 48px;
  }

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.b-login-register__content {

  @include media-breakpoint-up(lg) {
    padding: 48px;
  }
}
