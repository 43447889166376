.g-main-filter-extended {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(6, 1fr);
    row-gap: 2.1rem;

    .g-main-filter-extended__cell {

      &:first-child,
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6), {
        grid-row: span 2 / auto;
      }
    }
  }
}
