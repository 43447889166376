@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

$borderRadius: general.$border-base;
$borderColor: colors.$gray-200;
$paddingX: 16px;
$paddingY: 13px;

.f-base {

  &.--highlited {
    border: 2px solid colors.$conversion;
  }
}

.input__wrapper {

  .f-base {
    padding-left: 3rem;
  }

  .--highlited {
    border: 2px solid colors.$conversion;
  }
}

.f-input__wrapper {
  position: relative;

  &.--with-icon {

    .f-base {
      padding-left: 2.75rem;
    }
  }

  .input__icon {
    position: absolute;
    left: 1.5rem;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.25rem;
    width: 1.25rem;

    .icon {
      display: block;
      height: 1.25rem;
      width: 1.25rem;

      > img,
      > svg {
        display: block;
        height: 1.25rem;
        width: 1.25rem;
      }
    }
  }
}
