@use "./../../../scss/settings/s-colors.scss" as colors;

.g-pagination__button {
  display: flex;
  justify-content: center;
}

.g-pagination__wrapper {
  margin-bottom: 28px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 1px solid colors.$gray-200;
  padding-top: 12px;
  margin-top: 22px;

  .link {

    .icon {
      transform: rotate(180deg);
    }

    @include media-breakpoint-down(sm) {
      order: 2;
    }
  }

  @include media-breakpoint-down(sm) {
    border-top: none;

    .pagination {
      margin-bottom: 28px;
      width: 100%;
    }
  }
}
