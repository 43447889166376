.splide {
  visibility: visible;

  .splide__slide {
    width: auto !important;
  }

  .splide__track {
    overflow: visible;
  }

  .splide__sr {
    display: none !important;
  }
}
