.s-re-agency-description__title {
  margin-bottom: 14px;
  font-weight: 700;
}

.s-re-agency-description__image {
  max-width: 200px;
  margin-bottom: 44px;
}

.s-re-agency-description__description {
  line-height: 24px;
  width: calc(50% - 131px);

  @include media-breakpoint-down(xxl) {
    width: calc(50% - 100px);
  }

  @include media-breakpoint-down(lg) {
    width: calc(50% - 60px);
  }

  @include media-breakpoint-down(lg) {
    width: 100%;
    margin-bottom: 40px;
  }
}

.s-re-agency-description__address,
.s-re-agency-description__links {
  width: calc(25% - 131px);

  @include media-breakpoint-down(xxl) {
    width: calc(25% - 100px);
  }

  @include media-breakpoint-down(lg) {
    width: calc(25% - 60px);
  }

  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.s-re-agency-description__address {
  display: flex;

  .icon {
    margin-right: 14px;
  }

  @include media-breakpoint-down(lg) {
    margin-bottom: 32px;
  }
}

.s-re-agency-description__address-content {
  margin-bottom: 16px;
}

.s-re-agency-description__street-address {
  line-height: 24px;
  margin-bottom: 0;
}

.s-re-agency-description__city-address {
  font-weight: 700;
  margin-bottom: 4px;
}

.s-re-agency-description {

  .link__title {
    font-weight: 400;
  }

  .link {
    margin-bottom: 10px;
  }
}

.s-re-agency-description__address-wrapper {
  display: flex;
  flex-direction: column;

  .link {
    margin-bottom: 4px;
  }

  @include media-breakpoint-down(lg) {
    width: 50%;
  }
}

.s-re-agency-description__links {
  display: flex;
  flex-direction: column;
}

.s-re-agency-description__content {
  gap: 131px;
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-down(xxl) {
    gap: 100px;
  }

  @include media-breakpoint-down(xl) {
    gap: 60px;
  }

  @include media-breakpoint-down(lg) {
    gap: 0;
  }
}

.s-re-agency-description__buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.s-re-agency-description__buttons-item {

  @include media-breakpoint-down(md) {
    margin-bottom: 20px;

    &.--first {
      order: 1;
      width: 100%;
      margin-bottom: 0;
      margin-right: 0;

      .btn {
        width: 100%;
      }

      .btn__title {
        text-align: center;
        width: 100%;
        display: block;
      }
    }
  }
}
