@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.i-contact {
  display: flex;
  align-items: center;
  gap: 16px 12px;
  text-decoration-color: transparent;

  &:hover {
    color: colors.$primary;
    text-decoration-color: colors.$primary;
  }
}
