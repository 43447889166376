@use "./../../scss/settings/s-colors.scss" as colors;

@use "./../../scss/settings/s-general.scss" as general;

@use "sass:math";

.energy-label {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: general.$spacing * 0.5 14px;
    gap: 24px;
    border-radius: 3px 0 0 3px;

    &::before {
        content: "";
        position: absolute;
        display: block;
        top: 50%;
        right: 0;
        height: calc(100% / (#{math.sqrt(2)}));
        aspect-ratio: 1/1;
        transform: translate(50%, -50%) rotate(45deg);
        z-index: -1;
        border-radius: 0 3px;
    }

    .energy-label__heading {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            display: block;
            top: 50%;
            right: -12px;
            height: 21px;
            width: 1px;
            transform: translate(-50%, -50%);
            background-color: colors.$white;
            opacity: 0.5;
        }
    }

    .energy-label__perex {
        font-size: 12px;
        max-width: 80px;
        line-height: 12px;
        margin-bottom: 0;
        font-weight: 500;
    }

    &.--small {
        padding: general.$spacing * 0.5 general.$spacing;
        gap: 18px;

        .energy-label__heading {
            line-height: 24px;

            &::before {
                right: -9px;
            }
        }
    }

    &.--extremly_eco,
    &.--extremly_eco::before {
        background-color: colors.$extremly_eco;
    }

    &.--very_eco,
    &.--very_eco::before {
        background-color: colors.$very_eco;
    }

    &.--eco,
    &.--eco::before {
        background-color: colors.$eco;
    }

    &.--less_eco,
    &.--less_eco::before {
        background-color: colors.$less_eco;
    }

    &.--uneco,
    &.--uneco::before {
        background-color: colors.$uneco;
    }

    &.--very_uneco,
    &.--very_uneco::before {
        background-color: colors.$very_uneco;
    }

    &.--extremly_uneco,
    &.--extremly_uneco::before {
        background-color: colors.$extremly_uneco;
    }

    &.--mono {

        @include media-breakpoint-up(xxl) {
            background-color: colors.$white;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                border-style: solid;
                border-color: colors.$gray-600;
                border-width: 2px;
                border-right: none;
            }

            &::before {
                background-color: transparent;
                border-style: solid;
                border-color: colors.$gray-600;
                border-width: 2px;
                border-bottom: 0;
                border-left: 0;
                z-index: 2;
            }

            .energy-label__perex {
                display: none;
            }
        }
    }

    &.--mobile-unvisible {

        @include media-breakpoint-down(xxl) {
            display: none;
        }
    }
}
