@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.i-broker-review-response {
  background-color: colors.$gray-100;
  border-radius: 8px;
  width: 536px;
  max-width: 100%;
  padding: general.$spacing * 3;
  display: flex;
  flex-direction: column;
  gap: general.$spacing * 2;
}

.i-broker-review-response__header {
  display: flex;
  align-items: flex-start;
  gap: 19px;
}

.i-broker-review-response__img {
  width: general.$spacing * 6;
  box-shadow: colors.$shadow-02;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.i-broker-review-response__heading-block {
  flex-grow: 2;
  flex-shrink: 0;
}

.i-broker-review-response__label {
  font-style: italic;
  color: colors.$gray-300;
}

.i-broker-review-response__perex {
  margin-bottom: 0;
}
