@use "./../../scss/settings/s-colors.scss" as colors;

@use "./../../scss/settings/s-general.scss" as general;

.side-menu__wrapper {
    background-color: colors.$gray-100;
    padding: 16px;
    border-radius: 10px;

    &.--transparent {
        background-color: transparent;
    }

    .base-header__heading-wrapper {
        font-size: 18px;
        padding-bottom: 0;
        border-bottom: 1px solid colors.$primary-100;
    }

    .base-header {
        margin-bottom: 0;
    }

    @include media-breakpoint-down(xl) {
        display: none;
    }

    @include media-breakpoint-up(xl) {
        display: block !important;
    }

    &.--blue {
        padding: 20px 30px 20px 28px;
        .base-header__heading-wrapper {
            margin-bottom: 0;
            .base-header__heading {
                font-size: 17px;
                line-height: 1.5em;
                color: colors.$primary;
            }
        }
        .g-link-list {
            > * {
                border-bottom: 1px solid colors.$primary-100;;
            }
            .link {
                font-weight: 300;
                width: 100%;
                color: var(--bs-body-color);
                line-height: 2em;
                .link__title {
                    width: 100%;
                    .link__title-wrap {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        span {
                            &:last-child {
                                font-weight: 400;
                                color: colors.$primary;
                            }
                        }
                    }
                }
            }
        }
    }
}
