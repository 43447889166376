.f-register {
  max-width: 328px;
  margin-inline: auto;
}

.f-register__gdpr {

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.f-register__heading {
  font-size: 26px;
}
