@use "settings/s-colors" as colors;

.s-selected-filters__body {
  box-shadow: colors.$shadow-01;
  border-radius: 0 0 8px 8px;
  min-height: 72px;
  align-items: center;
  padding-inline: 12px;
}

.s-selected-filters__list {

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.s-selected-filters__buttons {

  @include media-breakpoint-down(lg) {
    margin-left: 0;
    width: 100%;
    justify-content: space-between;
  }
}

.s-selected-filters {

  &.--hidden {
    display: none;
  }
}

.s-selected-filters__label {
  font-size: 18px;
  line-height: 21px;

  @include media-breakpoint-down(lg) {
    display: none;
  }
}
