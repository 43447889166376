.s-related-project__flow {
  display: flex;
  flex-direction: column;
  gap: 48px;

  @include media-breakpoint-only(xl) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    .s-related-project__flow-cell {

      &:first-of-type {
        grid-column: span 8 / auto;
      }

      &:last-of-type {
        grid-column: span 4 / auto;
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.s-related-project__content {
  font-size: 18px;
  line-height: 24px;

  @include media-breakpoint-only(xl) {
    font-size: 16px;
  }
}
