@use "settings/s-colors" as colors;

.n-user {
  padding: 8px 19px 0;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    background-color: colors.$gray-300;
  }
}
