@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.s-dev-project-slide {
  overflow: hidden;

  .swiper-slide {
    height: auto;
  }
}

.s-dev-project-slide__slider {
  overflow: visible;
}

.s-dev-project-slide__bottom-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 32px;
  padding: 0 8px;
  margin-top: 24px;
}

.s-dev-project-slide__container {

  @include media-breakpoint-down(md) {
    max-width: 312px;
  }
}
