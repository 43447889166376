.s-estate-map__iframe-wrap {
  display: block;
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-top: 50%;

    @include media-breakpoint-down(md) {
      padding-top: calc(10 / 11 * 100%);
      padding-top: 100%;
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.s-estate-map__buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 32px;
}
