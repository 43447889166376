.f-password__toggle {
  --on-opacity: 0;
  --off-opacity: 1;

  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: grid;

  &.--active {
    --on-opacity: 1;
    --off-opacity: 0;
  }
}

.f-password__toggle-icon {
  grid-column-start: 1;
  grid-row-start: 1;
  width: 24px;
  height: 24px;

  &.--on {
    opacity: var(--on-opacity);
  }

  &.--off {
    opacity: var(--off-opacity);
  }
}
