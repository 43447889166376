@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.i-estate-detail-intro__youtube {
    display: block;
    border-radius: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    iframe {
        width: 100%;
        height: 100%;
        + a {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
}

