@use "./../../../scss/settings/s-colors.scss" as colors;

.entry-content {

  h2 {
    margin-bottom: 24px;
  }

  h3,
  h4 {
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 28px;
  }

  ul {

    li {
      position: relative;
      padding-left: 24px;

      &::before {
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        background-color: colors.$conversion;
        transform: rotate(45deg);
        position: absolute;
        top: 4px;
        left: 0;
      }
    }
  }

  a {
    text-decoration: none;
    margin-bottom: 16px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-bottom: 16px;
  }

  .gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 24px;

    li,
    img,
    a {
      margin: 0;
    }
  }

  .gallery-item {
    display: block;
    width: calc(25% - 12px);

    @include media-breakpoint-down(md) {
      width: calc(50% - 8px);
    }

    img {
      object-fit: cover;
    }
  }
}
