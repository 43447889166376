@use "./../../../scss/settings/s-general.scss" as general;

@use "./../../../scss/settings/s-colors.scss" as colors;

.switch {
  position: relative;

  input {
    height: 0;
    width: 0;
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  label {
    position: relative;
    height: general.$spacing * 3;
    display: block;
    padding-left: 53px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: general.$spacing * 3;
      width: 40px;
      border-radius: 100px;
      border-style: solid;
      border-width: 2px;
      border-color: colors.$gray-200;
      background-color: colors.$gray-100;
      transition: background-color 0.2s, border-color 0.2s;
    }

    &::after {
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      height: 16px;
      width: 16px;
      border-radius: 100%;
      background-color: colors.$primary;
      transform: translateX(4px);
      transition: background-color 0.2s, transform 0.2s;
      box-shadow: 0 1px 1px #00000029;
    }

    &:hover {
      cursor: pointer;
    }
  }

  input:checked + label {

    &::before {
      background-color: colors.$success;
      border-color: colors.$success;
    }

    &::after {
      transform: translateX(20px);
      background-color: colors.$white;
    }
  }

  input:disabled + label {
    pointer-events: none;

    &::before {
      border-color: colors.$gray-200;
      border-width: 1px;
    }

    &::after {
      transform: translateX(4px);
      background-color: colors.$gray-200;
    }
  }
}
