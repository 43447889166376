.g-posts {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  padding-top: 28px;

  @include media-breakpoint-down(lg) {
    flex-direction: row;
    flex-wrap: wrap;

    li {
      max-width: 100%;
      width: calc(50% - 20px);
    }

    .i-post-slide {
      max-width: 100%;
    }

    .i-post-slide__image-col {
      width: auto;

      img {
        width: 100%;
      }
    }
  }

  @include media-breakpoint-down(sm) {

    li {
      width: 100%;
    }
  }
}
