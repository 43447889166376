@use "../../../scss/settings/s-colors.scss" as colors;

.i-login-cta {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  background-color: colors.$gray-100;
  padding: 24px 116px 24px 24px;
  border-radius: 10px;
  width: 440px;
  max-width: 100%;
  margin-top: 2.8em;

  @include media-breakpoint-down(xl) {
    width: 100%;
    padding: 16px;
  }
}

.i-login-cta__content-title {
  font-weight: 700;
  margin-bottom: 0.5em;

  @include media-breakpoint-down(xl) {
    font-size: 26px;
  }
}

.i-login-cta__image {
  position: absolute;
  top: 52px;
  right: 0;
  transform: translateX(50%);

  @include media-breakpoint-down(xl) {
    width: 174px;
    top: 0;
    right: 0;
    transform: translate(30%, -20%);
  }
}

.i-login-cta__content-perex {
  line-height: 27px;
  font-size: 18px;
  margin-bottom: 48px;

  @include media-breakpoint-down(xl) {
    margin-bottom: 16px;

    br {
      display: none;
    }
  }
}

.i-login-cta__content-buttons {
  display: flex;
  gap: 16px;
  white-space: nowrap;
}
