@use "./../../scss/settings/s-colors.scss" as colors;

.breadcrumbs__item-icon {
  width: 12px;
  height: 12px;
  transform: translateY(6px);

  svg {
    width: 100%;
    height: 100%;
  }
}

.breadcrumbs__item {
  display: flex;
  gap: 0 16px;

  &:first-of-type {

    .breadcrumbs__item-icon {
      display: none;
    }
  }

  a,
  span {
    color: colors.$gray-300;
  }
}

.breadcrumbs__dropdown {
  display: none;

  path {
    stroke: colors.$gray-300;
  }

  @include media-breakpoint-down(md) {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @include media-breakpoint-down(md) {
    position: relative;
    padding-right: 32px;

    .breadcrumbs__item:not(.--last) {
      display: none;
    }

    &.--active {

      .breadcrumbs__item:not(.--last) {
        display: flex;
      }

      .breadcrumbs__dropdown {
        top: auto;
        bottom: 0;
        transform: scale(-1);
      }
    }
  }

  &.--color-white {

    .breadcrumbs__dropdown {

      path {
        stroke: colors.$white;
      }
    }

    .breadcrumbs__item {

      a,
      span {
        color: colors.$white;
      }
    }
  }
}
