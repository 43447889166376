@use "./../../../scss/settings/s-colors.scss" as colors;

.s-re-agency-detail-branches__container {
  position: relative;

  .swiper-wrapper {
    position: static;
  }
}

.s-re-agency-detail-branches__slider {
  overflow: visible;
  max-width: none;
  margin: 0;
  position: static;

  .swiper-slide {
    height: auto;
    width: 430px;
    align-self: stretch;

    @include media-breakpoint-down(sm) {
      width: 320px;
    }
  }
}

.s-re-agency-detail-branches {
  overflow: hidden;

  .base-header {
    margin-bottom: 0;
  }

  .checkbox-fieldset__list {
    flex-direction: row;
  }
}

.s-re-agency-detail-branches__search-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 60px;

  .btn__title {
    display: none;
  }

  .f-search {
    width: 440px;
    max-width: 100%;
    flex-shrink: 0;
  }

  .f-search__button {

    .btn {
      height: 100%;
    }
  }

  .f-base {
    font-style: italic;
  }

  .checkbox-fieldset {
    flex-direction: row;
    align-items: center;
  }
}

.s-re-agency-detail-branches__bottom-wrapper {
  margin-top: 32px;

  .swiper-arrows {
    justify-content: flex-end;
  }
}
