@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.s-broker-review-slide {
  overflow: hidden;

  .base-header {
    margin-bottom: 0;
  }
}

.s-broker-review-slide__container {
  position: relative;
  padding-bottom: 72px;

  .swiper-wrapper {
    position: static;
  }
}

.s-broker-review-slide__item {
  width: auto;
}

.s-broker-review-slide__slider {
  overflow: visible;
  max-width: none;
  margin: 0;
  position: static;
}

.s-broker-review-slide__select {
  margin-bottom: general.$spacing * 3;
}

.s-broker-review-slide__charts-wrapper {
  display: flex;
  align-items: center;
  padding: 0;
  padding-bottom: general.$spacing * 6;
  gap: 24px;

  @include media-breakpoint-down(lg) {
    justify-content: space-between;
  }
}

.s-broker-review-slide__chart {
  flex-grow: 2;
  flex-shrink: 3;
}

.s-broker-review-slide__bottom-wrapper {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 32px;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 auto;
  margin-top: general.$spacing * 3;
  transform: translateX(-50%);
  padding: 0 8px;

  @include media-breakpoint-up(sm) {
    width: 540px; //container width
  }

  @include media-breakpoint-up(md) {
    width: 720px; //container width
  }

  @include media-breakpoint-up(lg) {
    width: 864px; //container width
    padding: 0 16px;
  }

  @include media-breakpoint-up(xl) {
    width: 1140px; //container width
  }

  @include media-breakpoint-up(xxl) {
    width: 1358px; //container width
  }

  @include media-breakpoint-down(sm) {
    margin: 0;
    margin-top: general.$spacing * 3;
    width: 100vw;
  }

  @media (min-width: 1920px) {
    transform: translateX(-50%);
  }
}
