@use "./../../../scss/settings/s-colors.scss" as colors;

.s-link-navigation {
  background-color: colors.$primary-150;
}

.s-link-navigation__content {
  padding-top: 58px;
  border-top: 1px solid colors.$gray-200;
}

.s-link-navigation__cta {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 53px;

  .base-header {
    margin: 0  8px 0 0;
    font-size: 18px;
  }

  .base-header__heading {
    display: none;
  }

  .link {
    font-weight: 400;
    font-size: 18px;
  }

  @include media-breakpoint-down(lg) {
    flex-wrap: wrap;
    margin-bottom: 50px;

    .base-header {
      width: 100%;
      margin: 0 0 16px;
    }
  }
}
