@use "./../../scss/settings/s-colors.scss" as colors;

@use "./../../scss/settings/s-general.scss" as general;

.footer {
  background-color: colors.$gray-600;
}

.footer__top {
  padding: general.$spacing * 6 0;

  @include media-breakpoint-down(md) {
    padding: general.$spacing * 8 0;
    padding-bottom: 0;
  }
}

.footer__top-content {

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.footer__bottom {
  background-color: colors.$gray-700;
  padding-top: general.$spacing * 6;
  padding-bottom: general.$spacing * 5;

  @include media-breakpoint-down(md) {
    padding-bottom: general.$spacing * 9;
  }
}

.footer__bottom-content {
  display: flex;
  gap: 24px;
  align-items: center;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    gap: 64px;
  }
}

.footer__copy {
  color: colors.$gray-300;
  max-width: 760px;
}

.footer__signiture {
  margin-left: auto;
  height: general.$spacing * 3;
  flex-shrink: 0;

  a {
    display: block;
  }

  * {
    height: 100%;
  }

  @include media-breakpoint-down(md) {
    margin-left: 0;
  }
}
