@use "./../../../scss/settings/s-colors.scss" as colors;

.g-link-navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  margin: 0 auto;

  @include media-breakpoint-down(lg) {
    width: 100%;
    justify-content: none;
  }
}

.g-link-navigation__item {
  border-right: 2px solid colors.$black;
  padding: 0 16px;
  margin-bottom: 16px;

  .link {
    font-size: 18px;
  }

  &:last-child {
    border-right: none;
  }

  @include media-breakpoint-down(lg) {
    padding: 0 12px;
    margin-bottom: 12px;
  }
}
