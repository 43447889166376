@use "./../../../scss/settings/s-colors.scss" as colors;

.i-ups {
  display: flex;
  align-items: center;
}

.i-ups__image {
  background-color: colors.$white;
  border-radius: 50%;
  position: relative;
  width: 115px;
  height: 115px;
  box-shadow: 0 5px 11px 0 rgba($color: #000, $alpha: 11%), 0 0 0 11px colors.$primary-100;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 43px;
  flex-shrink: 0;

  img {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }

  @include media-breakpoint-down(xxl) {
    width: 90px;
    height: 90px;
  }

  @include media-breakpoint-down(xl) {
    width: 80px;
    height: 80px;
    margin-right: 24px;
  }

  @include media-breakpoint-down(lg) {
    width: 115px;
    height: 115px;
    margin-right: 32px;
  }
}

.i-ups__heading {
  margin-bottom: 8px;
}
