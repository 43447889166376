@use "./../../scss/settings/s-colors.scss" as colors;

.main-header__container {
  display: flex;
  align-items: center;
  min-height: 56px;
  position: relative;

  @include media-breakpoint-up(md) {
    min-height: 62px;
  }
}

.main-header__content {
  display: flex;
  flex-shrink: 0;
  margin-left: auto;
  column-gap: 24px;
  align-items: center;

  @include media-breakpoint-down(md) {
    display: none;
  }

  @include media-breakpoint-up(md) {
    padding-right: 24px;
  }
}

.main-header__buttons-group {
  display: flex;
  gap: 8px;
}

.main-header__notifications-group {
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
  flex-grow: 1;
  gap: 8px;
}

.main-header__notification-icon {
  display: flex;
  height: 24px;
  width: 24px;
  margin-inline: auto;
  justify-content: center;
  align-items: center;

  path,
  rect,
  line {
    stroke: colors.$white;
  }
}

.main-header__mobile-buttons {
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.main-header__buttons-group-mobile {
  display: none;

  @include media-breakpoint-down(md) {
    display: flex;
    margin-left: auto;
  }
}

.main-header__logo {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include media-breakpoint-up(md) {
    padding-left: 24px;
  }
}

body[data-header="full-width"] {

  > header {

    .main-header__container {
      max-width: 100%;
      padding-left: 23px;
      padding-right: 23px;
    }
  }
}

.main-header {
  background-color: colors.$primary;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 150;
  position: fixed;
  transition: transform 0.3s ease;

  // padding: 8px 0;

  &.headroom--pinned {
    transform: translateX(-50%) translateY(0%);
  }

  &.headroom--unpinned {
    transform: translateX(-50%) translateY(-100%);
  }

  &.--dark {
    // .main-header__container {
    //   align-items: flex-start;
    // }

    @include media-breakpoint-up(md) {
      padding: 16px 0;
      background: transparent linear-gradient(180deg, #062841 0%, #06284100 100%) 0% 0% no-repeat padding-box;

      // basicaly status is open

      &.headroom--frozen {
        background: colors.$black;
      }
    }
  }
}

.main-header__main-nav-open,
.main-header__main-nav-close,
.main-header__mobile-buttons {

  &.--hidden {
    display: none;
  }
}

.main-header__breadcrumbs {

  @include media-breakpoint-up(md) {
    padding-left: 24px;
  }
}
