@use "./../../../scss/settings/s-colors.scss" as colors;

.i-tab-navigation {
  font-size: 26px;
  cursor: pointer;
  padding: 0 24px;
  display: block;
  position: relative;
  transition: color 0.3s ease;

  @include media-breakpoint-down(md) {
    font-size: 21px;
    padding: 0 16px;
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    left: 50%;
    bottom: -8px;
    transform: translateX(-50%) scaleY(0);
    transform-origin: bottom center;
    width: 100%;
    height: 4px;
    background-color: colors.$conversion;
    transition: transform 0.3s ease;
  }

  &.--active {
    color: colors.$primary;

    &::after {
      transform: translateX(-50%) scaleY(1);
    }
  }
}
