@use "./../../scss/settings/s-colors.scss" as colors;

@use "./../../scss/settings/s-general.scss" as general;

.share {
  width: general.$spacing * 6;
  position: relative;
  cursor: pointer;

  .cta-button {
    z-index: 10;

    .icon {
      pointer-events: none;
    }
  }

  .g-share-buttons {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translate(-48px, -50%);

    @include media-breakpoint-down(sm) {
      top: auto;
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, 48px);
    }
  }

  &.--active {

    .g-share-buttons {
      pointer-events: inherit;
      opacity: 1;
      z-index: 1;
      transform: translate(10px, -50%);

      @include media-breakpoint-down(sm) {
        transform: translate(-50%, -10px) scaleY(100%);
      }
    }
  }
}
