@use "./../../scss/settings/s-colors.scss" as colors;

@use "./../../scss/settings/s-general.scss" as general;

.share-button {
  flex-shrink: 0;
  height: general.$spacing * 6;
  width: general.$spacing * 6;
  border-radius: 50%;
  background-color: colors.$gray-300;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: background-color 0.2s;
  border: none;
  outline: none;
  box-shadow: 0 0 0 2px colors.$white;

  * {
    pointer-events: none;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: colors.$conversion;
    border-radius: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
  }

  path,
  circle,
  line {
    stroke: colors.$white;
  }

  &:hover {
    background-color: transparent;
    cursor: pointer;
  }

  &.--whatsapp {

    &::before {
      background-color: colors.$whatsapp;
    }
  }

  &.--messenger {

    &::before {
      background: colors.$messenger;
    }
  }

  &.--facebook {

    &::before {
      background-color: colors.$facebook;
    }
  }

  &.--twitter {

    &::before {
      background-color: colors.$twitter;
    }
  }

  &.--copied {
  background-color: colors.$success;

  &:hover,
  &:focus,
  &:active {
    background-color: colors.$success;
  }
}
}
