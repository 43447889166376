@use "./../../scss/settings/s-colors.scss" as colors;

.link__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    svg {
        transition: transform 0.3s ease;
        will-change: transform;
    }
}

.link__title {
    display: flex;
    min-width: 0;
    overflow: hidden;
}

.link__title-wrap {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.link {
    display: inline-flex;
    transition: text-decoration-color 0.3s ease;
    transition-property: text-decoration-color, color;
    text-decoration: underline;
    align-items: center;
    column-gap: 8px;
    font-size: 16px;
    font-weight: 400;
    flex-shrink: 0;

    &.--black {
        color: colors.$black;
        text-decoration-color: transparent;

        &:hover,
        &:focus {
            color: colors.$black;
            text-decoration-color: inherit;
        }
    }

    &.--primary {
        color: colors.$primary;
        text-decoration-color: transparent;

        &:hover,
        &:focus {
            color: colors.$primary;
            text-decoration-color: inherit;
        }
    }

    &.--conversion {
        color: colors.$conversion-600;
        text-decoration-color: transparent;

        &:hover,
        &:focus {
            color: colors.$conversion-600;
            text-decoration-color: inherit;
        }
    }

    &.--light-gray {
        color: colors.$gray-200;
        text-decoration-color: transparent;

        &:hover,
        &:focus {
            color: colors.$white;
            text-decoration-color: inherit;
        }
    }

    &.--gray {
        color: colors.$gray-300;
        text-decoration-color: transparent;

        &:hover,
        &:focus {
            color: colors.$gray-300;
            text-decoration-color: inherit;
        }
    }

    &.--white {
        color: colors.$white;
        text-decoration-color: transparent;

        &:hover,
        &:focus {
            color: colors.$white;
            text-decoration-color: inherit;
        }

        line {
            stroke: colors.$white;
        }
    }

    &.--icon-before {

        .link__icon {
            order: -1;
        }
    }

    &.--animation-right {

        &:hover {

            svg {
                transform: translateX(8px);
            }
        }
    }

    &.--animation-zoom {

        &:hover {

            svg {
                transform: scale(1.1);
            }
        }
    }

    &.--underline-initial {
        text-decoration-color: inherit;

        &:hover,
        &:focus {
            text-decoration-color: transparent;
        }
    }

    .link__icon {
        &.--with-notification-button {
            position: relative;
            .link__icon__notification-button {
                position: absolute;
                background-color: colors.$red;
                color: colors.$white;
                border-radius: 160px;
                height: 16px;
                min-width: 16px;
                font-size: 10px;
                line-height: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 0;
                left: 100%;
                transform: translate(-75%, -35%);
                padding: 2px 4px;
            }
        }
    }
}
