@use "./../../../scss/settings/s-general.scss" as general;

@use "./../../../scss/settings/s-colors.scss" as colors;

.g-broker-meta {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: general.$spacing 0;
}
