@use "./../../scss/settings/s-colors.scss" as colors;

@use "./../../scss/settings/s-general.scss" as general;

.tooltip {
  white-space: nowrap;
  padding: general.$spacing 20px;
  background-color: colors.$gray;
  border-radius: 5px;
  color: colors.$white;
  display: inline-block;
  position: relative;
  text-align: center;
  pointer-events: none;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%) rotate(-45deg);
    height: 14px;
    width: 14px;
    background-color: colors.$gray;
  }
}
