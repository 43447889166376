@use "./../../scss/settings/s-colors.scss" as colors;

@use "./../../scss/settings/s-general.scss" as general;

.review-feedback {
  display: flex;
  align-items: center;
  padding-top: general.$spacing * 3;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    width: 100%;
    background-color: colors.$gray-200;
  }

  h4 {
    font-weight: 400;
    font-size: 16px;
    margin-right: 20px;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    gap: general.$spacing * 3;
    justify-content: center;
  }
}

.review-feedback__wrapper {
  display: flex;
  align-items: center;
  gap: 0 36px;
  margin-left: auto;

  @include media-breakpoint-down(sm) {
    margin-left: 0;
    width: 100%;
    justify-content: space-between;
  }
}
