.g-usp {
  display: flex;
  justify-content: space-between;
  gap: 112px;

  @include media-breakpoint-down(xxl) {
    gap: 74px;
  }

  @include media-breakpoint-down(xl) {
    gap: 24px;
  }

  @include media-breakpoint-down(lg) {
    flex-wrap: wrap;
    gap: 48px;
  }
}
