@use "./../../scss/settings/s-colors.scss" as colors;

.base-header {
    position: relative;
    text-align: center;
    margin: 0 auto;
    max-width: 840px;
    margin-bottom: 48px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &.--left {
        text-align: left;
        margin-right: 0;
        margin-left: 0;
        max-width: 100%;
    }

    &.--center {
        justify-content: center;

        .base-header__heading-wrapper {
            justify-content: center;
        }
    }

    &.--underline {
        max-width: 100%;
    }

    &.--fw400 {

        .base-header__heading {
            font-weight: 400;
        }
    }

    &.--fw700 {

        .base-header__heading {
            font-weight: 700;
        }
    }

    &.--m-bottom-0 {
        margin-bottom: 0;
    }

    &.--size-md {
        margin-bottom: 24px;

        .base-header__heading {

            @extend %base-h2;
        }
    }

    &.--with-seo-box-right-under {
        margin-bottom: 0.5rem;

        + .seo-box {
            margin-bottom: 32px;
        }

        &.--m-bottom-0 {

            + .seo-box {
                margin-bottom: 0;
            }
        }

        &.--size-md {

            + .seo-box {
                margin-bottom: 24px;
            }
        }
    }

    .base-h1 ~ .base-header__number {

        @extend %base-h1;

        font-weight: 400;
    }

    .base-h2 ~ .base-header__number {

        @extend %base-h2;

        font-weight: 400;
    }

    .base-h3 ~ .base-header__number {

        @extend %base-h3;

        font-weight: 400;
    }

    .base-h4 ~ .base-header__number {

        @extend %base-h4;

        font-weight: 400;
    }

    &.base-h1 {
        .base-header__heading {
            @extend %base-h1;
        }
    }
    &.base-h2 {
        .base-header__heading {
            @extend %base-h2;
        }
    }
    &.base-h3 {
        .base-header__heading {
            @extend %base-h3;
        }
    }
    &.base-h4 {
        .base-header__heading {
            @extend %base-h4;
        }
    }
}

.base-header__paragraph {
    margin-bottom: 0;

    span {
        color: colors.$primary;
        font-weight: 700;
    }
}

.base-header {

    .base-header__heading-wrapper {
        display: flex;
        align-items: flex-end;
        gap: 17px;
    }

    &.--underline {

        .base-header__heading-wrapper {
            padding-bottom: 8px;
            margin-bottom: 24px;
            border-bottom: 1px solid colors.$gray;
        }
    }
}

.--pre-section-header {
    margin-top: 21px;
    .base-header {
        margin-bottom: 27px;
    }
    &.--pre-estate-section-header {
        padding-top: 2.563rem;
        margin-top: 0;
        .base-header {
            margin-top: 0.5625rem;
            margin-bottom: .5rem;
        }
        + .s-estates-header {
            padding-top: 0;
        }
    }
}

a.base-header__heading-wrapper {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s ease-in-out;

    &:hover {
        color: colors.$primary;
    }
}

.base-header__heading {

    > a {
        color: inherit;
        text-decoration-color: transparent;
        transition: all 0.3s ease-in-out;

        &:hover {
            text-decoration-color: inherit;
            color: inherit;
        }
    }
}
