.seo-box {
    header {
        display: flex;
        flex-flow: column nowrap;
        &.centered {
            justify-content: center;
        }
        @media screen and (min-width: 600px) {
            flex-flow: row nowrap;
        }
        align-items: center;
        grid-gap: 1em;
        p {
            margin-bottom: 0;
        }
    }
    main {
        margin-top: 2rem;
        &:not(.js-toggle-show),
        &.js-toggle-hidden,
        &.js-toggle-hiden {
            display: none;
        }
    }
}