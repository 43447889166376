@use "./../../../scss/settings/s-general.scss" as general;

.footer-social-group {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 20px;
  margin-bottom: general.$spacing * 7;

  li {
    display: block;
    max-height: 32px;
  }

  @include media-breakpoint-down(lg) {
    gap: 16px;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: general.$spacing * 8;
    gap: 16px 50px;
  }
}
