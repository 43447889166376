.error-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  margin: 0 auto;

  .error-section__content-icon {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 3rem;
  }

  .error-section__content-title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 3rem;
  }

  .error-section__content-description {
    font-size: 24px;
    margin-bottom: 3rem;
  }

  .error-section__content-buttons {
    display: flex;
    gap: 16px;
    white-space: nowrap;
    margin-bottom: 1.5rem;
  }
}
