.g-posts-slide {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include media-breakpoint-down(lg) {
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    height: 100%;
  }

  @include media-breakpoint-down(md) {
    gap: 8px;
  }
}
