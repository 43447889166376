@use "./../../scss/settings/s-colors.scss" as colors;

@use "./../../scss/settings/s-general.scss" as general;

.badge {
  background-color: colors.$magenta;
  border-radius: 24px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: general.$spacing * 1.5 22px;
  color: colors.$white;
  min-height: general.$spacing * 6;
  font-weight: bold;

  .badge__title {
    font-size: general.$spacing * 2;
    line-height: general.$spacing * 2;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .badge__perex {
    font-size: 10px;
    line-height: general.$spacing * 2;
  }

  &.--turquoise {
    background-color: colors.$turquoise;
  }

  &.--green {
    background-color: colors.$green;
  }

  &.--yellow {
    background-color: colors.$yellow;
  }

  &.--light-blue {
    background-color: colors.$light-blue;
  }

  &.--gray {
    background-color: colors.$gray;
  }

  &.--red {
    background-color: colors.$red;
  }

  &.--dark-blue {
    background-color: colors.$dark-blue;
  }

  &.--black {
    background-color: colors.$black;
  }

  &.--small {
    min-width: 14px;
    min-height: 14px;
    padding: 1px 4px;

    .badge__title {
      font-size: 10px;
      line-height: general.$spacing;
    }
  }

  &.--circle {
    padding: general.$spacing * 1 8px;
    width: 48px;

    .badge__perex {
      margin-top: -2px;
    }
  }
}
