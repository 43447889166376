@use "settings/s-colors" as colors;

.i-navigation {
  --icon-size: 32px;
  --background: #{colors.$white};

  text-decoration: none;
  text-align: center;
  color: colors.$primary;
  cursor: pointer;

  &.--lg {
    --icon-size: 48px;

    .icon {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      max-width: 24px;
    }
  }

  &:hover,
  &.--active {
    --background: #{colors.$primary-100};
  }
}

.i-navigation__icon {
  background-color: var(--background);
  border-radius: 50%;
  width: var(--icon-size);
  height: var(--icon-size);
  box-shadow: 0 1px 1px #00000029;
  transition: background-color 0.3s ease;
}
