@use "./../../scss/settings/s-colors.scss" as colors;


ul.messages-list {
    // ROW
    > li {
        display: flex;
        flex-flow: column nowrap;
        gap: 12px 28px;
        padding: 14px 0;

        + li,
        &:first-child {
            border-top: 1px solid colors.$primary-150;
        }

        > .i-estate {
            @include media-breakpoint-down(md) {
                .i-estate__footer-button {
                    display: none;
                }
            }
        }

        > .timestamp {
            margin-top: 1rem;
            display: flex;
            flex-flow: row nowrap;
            gap: 1.5rem .5rem;
            p {
                margin-bottom: 0;
            }
        }

        > .message {
            margin-bottom: 1rem;
            p {
                margin-bottom: 0;
            }
        }

        > .realtor-info {

        }

        > .delete {
            margin-top: .5rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }


        .btn {
            &.--icon-before {
                column-gap: 11px;
            }
            &.--white {
                .btn__title {
                    font-weight: 400;
                    color: colors.$danger;
                }
                .btn__icon {
                    .icon {
                        height: 100%;
                        max-width: 24px;
                        svg {
                            height: unset;
                            width: unset;
                        }
                    }
                    path {
                        fill: none;
                    }
                }
            }
        }


        @include media-breakpoint-up(md) {
            //display: grid;
            //grid-template-columns: minmax(0, 1fr);
            //grid-template-rows: auto auto auto;
        //minmax(0, 465fr) minmax(0, 126fr) minmax(0, 243fr) minmax(0, 167fr) minmax(0, 100fr)
            @include media-breakpoint-down(lg) {
                > .i-estate {
                    grid-template-columns: 15rem minmax(0, 1fr);
                    .i-estate__description {
                        display: none;
                    }
                    .i-estate__footer-button {
                        display: none;
                    }
                    .i-estate__footer-logo {
                        display: none;
                    }
                    .i-estate__header-title {
                        font-size: 1.2rem;
                    }
                }
            }
        }

        @include media-breakpoint-up(lg) {
            display: grid;
            grid-template-columns: minmax(0, 1fr) auto;
            grid-template-rows: repeat(4, auto);
            > .i-estate {
                grid-row: 1 / 2;
                grid-column: 1 / -1;


            }
            > .timestamp {
                grid-row: 2 / 3;
                grid-column: 1 / 2;
            }
            > .message {
                grid-row: 3 / 4;
                grid-column: 1 / 2;
            }
            > .realtor-info {
                grid-row: 2 / 4;
                grid-column: 2 / 3;
            }
            > .delete {
                grid-column: 1 / -1;
                grid-row: 4 / 5;
                margin-top: 0;
            }

        }
        @include media-breakpoint-up(xl) {
            grid-template-columns: minmax(0, 1fr) auto auto;
            grid-template-rows: repeat(3, auto);

            > .i-estate {
                grid-column: 1 / -1;
                grid-row: 1 / 2;
            }
            > .timestamp {
                grid-row: 2 / 3;
                grid-column: 1 / 2;
            }
            > .message {
                grid-row: 3 / 4;
                grid-column: 1 / 2;
            }
            > .realtor-info {
                grid-column: 2 / 3;
                grid-row: 2 / 4;
            }
            > .delete {
                grid-column: 3 / 4;
                grid-row: 2 / 4;
                margin-top: 0;
            }
        }
        @include media-breakpoint-up(xxl) {
            grid-template-columns: minmax(0, 420fr) auto minmax(0, 200fr) minmax(0, 240fr) auto;
            grid-template-rows: auto;
            align-items: flex-start;
            > .i-estate {
                grid-column: auto;
                grid-row: auto;
                grid-template-columns: 15rem minmax(0, 1fr);
                .i-estate__description {
                    display: none;
                }
                .i-estate__footer-button {
                    display: none;
                }
                .i-estate__footer-logo {
                    display: none;
                }
                .i-estate__header-title {
                    font-size: 1.2rem;
                }
            }
            > .timestamp {
                grid-row: auto;
                grid-column: auto;
                flex-flow: column nowrap;
                margin-top: 0;
                gap: .25rem;
            }
            > .message {
                grid-row: auto;
                grid-column: auto;
            }
            > .realtor-info {
                grid-column: auto;
                grid-row: auto;
            }
            > .delete {
                grid-column: auto;
                grid-row: auto;
                margin-top: 0;
            }
        }
    }

    //&.--with-column-delete {
    //    > li {
    //        > .content {
    //            grid-template-columns: minmax(0, 465fr) minmax(0, 126fr) minmax(0, 243fr) minmax(0, 167fr) minmax(0, 100fr);
    //        }
    //    }
    //}
}
