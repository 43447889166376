@use "./../../../scss/settings/s-colors.scss" as colors;

.--pre-section-header {
  + .s-real-estate-agency {
    padding-top: 0;
  }
}

.s-real-estate-agency {

  .base-header {
    max-width: 100%;
    margin-bottom: 24px;
  }

  .f-base {
    font-style: italic;
  }

  .f-search {
    width: 100%;
    max-width: 550px;

    @include media-breakpoint-down(lg) {
      margin-bottom: 24px;

      .btn {
        width: auto;
      }

      .btn__title {
        display: table;
        transform: translateX(0);
      }
    }
    @include media-breakpoint-down(sm) {
      .btn__title {
        display: none;
      }
    }
  }

  .s-real-estate-agency__button {
    background-color: colors.$gray-100;

    path {
      fill: none;
    }

    @include media-breakpoint-down(md) {
      margin: 0 auto;
    }
  }
}

.s-real-estate-agency__wrapper {
  margin-bottom: 72px;

  @include media-breakpoint-down(lg) {
    margin-bottom: 24px;
  }

  .real-estate-agency-search__form {
    display: flex;
    flex-flow: row wrap;

    .f-search {
      margin-bottom: 0;
    }

    gap: 16px;
  }
}
