@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.s-category-cards__form-wrap {
  max-width: 1005px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%) translateY(50%);
  z-index: 5;
}

.s-category-cards__form {
  border-radius: general.$border-base;
  background-color: colors.$white;
  padding: 40px 54px;
  box-shadow: colors.$shadow;

  @include media-breakpoint-down(md) {
    padding: 16px;
  }
}

.s-category-cards {
  position: relative;

  .base-header__heading {
    font-weight: 700;

    @include media-breakpoint-up(lg) {
      font-size: 67px;
    }
  }

  .base-header__paragraph {
    font-size: 18px;
  }
}
