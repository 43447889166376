@use "./../../../scss/settings/s-colors.scss" as colors;

.i-ad__body {
  font-size: 18px;
}

.i-ad__content {
  display: flex;
  flex-direction: column;
  max-width: 350px;
}

.i-ad__flow {
  display: flex;
  column-gap: 48px;
  row-gap: 16px;
  padding: 0 24px;
}

.i-ad__header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.i-ad__heading {
  font-size: 21px;
  font-weight: bold;
  color: colors.$primary;
}

.i-ad__sub-heading {
  font-size: 18px;
  font-weight: bold;
}
