@use "./../../../scss/settings/s-general.scss" as general;

@use "./../../../scss/settings/s-colors.scss" as colors;

.i-highlighted-broker {
  box-shadow: colors.$shadow-01;
  border-radius: 10px;
  background-color: colors.$white;
  display: flex;
  align-items: flex-start;
  height: 100%;

  @include media-breakpoint-down(xl) {
    padding: general.$spacing * 2;
  }

  @include media-breakpoint-down(md) {
    box-shadow: none;
    padding: 0;
    position: relative;
    background-color: transparent;

    &::before {
      content: "";
      position: absolute;
      bottom: general.$spacing * -3;
      height: 1px;
      width: 100%;
      background-color: colors.$gray-200;
    }
  }
}

.i-highlighted-broker__image-link {
  box-shadow: colors.$shadow-02;
  width: 168px;
  border-radius: 10px;
  flex-shrink: 0;

  @include media-breakpoint-down(xl) {
    width: 80px;
  }
}

.i-highlighted-broker__content {
  padding: general.$spacing * 2 general.$spacing * 3;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  height: 100%;

  @include media-breakpoint-down(xl) {
    padding: 0 26px;
    padding-right: 0;
    height: 100%;
  }
}

.i-highlighted-broker__heading {
  font-weight: bold;
  margin-bottom: general.$spacing;

  a {
    text-decoration-color: transparent;
    color: colors.$black;
    display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      text-decoration-color: colors.$black;
    }
  }
}

.i-highlighted-broker__bottom-part {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;

  .img,
  .img img {
    border-radius: 0;
  }
}

.real-estate-img {
  height: general.$spacing * 4;
}
