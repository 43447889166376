@use "./../../scss/settings/s-general.scss" as general;

@use "./../../scss/settings/s-colors.scss" as colors;

.fancybox__thumbs {
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
}

.carousel__slide {
  width: 16px !important;
  height: 12px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border: 2px solid transparent;
    background-color: #fff;
    box-shadow: 0 3px 6px #000000c9;
    border-radius: 50%;
  }

  * {
    display: none;
  }

  &.is-nav-selected {

    &::before {
      box-shadow: 0 3px 6px #000000c9, 0 0 0 2px #fff;
      background-color: transparent;
      border-radius: 50%;
    }
  }
}

.fancybox__button--close {
  position: relative;

  &::after {
    content: "Zavřít";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    color: white;
    font-size: 18px;
    width: 50px;
    height: 18px;
    transform: translate(-100%, calc(-50% - 2px));
  }
}

.fancybox__nav {

  button.carousel__button {
    width: general.$spacing * 10;
    height: general.$spacing * 10;
    box-shadow: 0 0 0 1px colors.$white;
    background-color: transparent;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      width: 100%;
      filter: blur(3px) brightness(15);
      background-color: colors.$white;
      opacity: 0.2;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
  }
}

html.with-fancybox {

  .s-estate-detail-intro__cta-button {
    position: absolute;
    top: 16px;
    left: 41px;
    z-index: 1060;
  }

  .s-estate-detail-intro__share-buttons {
    margin-right: 60px;
  }

  .s-estate-detail-intro__cta-button-tooltip {
    display: block;
  }
}
