@use "settings/s-colors.scss" as colors;

.s-main-filter__footer {
    display: flex;
    justify-content: center;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        display: block;
        left: 50%;
        top: -10px;
        transform: translateX(-50%);
        background-color: colors.$gray-200;
        width: 100%;
        height: 1px;
    }

    @include media-breakpoint-down(sm) {

        .btn {
            width: 100%;
            display: block;
        }
    }


}

.s-main-filter {
    + .s-estates-header {
        .breadcrumbs {
            + .base-header {
                margin-top: 9px;
            }
        }
    }
}