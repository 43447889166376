@use "./../../scss/settings/s-general.scss" as general;

@use "./../../scss/settings/s-colors.scss" as colors;

.base-section {
  --padding-y: #{general.$spacing * 10};

  padding-top: var(--padding-y);
  padding-bottom: var(--padding-y);

  @include media-breakpoint-down(lg) {
    --padding-y: #{general.$spacing * 7};

    padding-top: var(--padding-y);
    padding-bottom: var(--padding-y);
  }

  @include media-breakpoint-down(md) {
    --padding-y: #{general.$spacing * 5};

    padding-top: var(--padding-y);
    padding-bottom: var(--padding-y);
  }

  &.--p-top-0 {
    padding-top: 0 !important;
  }

  &.--p-bottom-0 {
    padding-bottom: 0 !important;
  }

  &.--p-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &.--primary-100 {
    background-color: colors.$primary-100;
  }

  &.--spacing-md {
    --padding-y-md: #{general.$spacing * 5};

    padding-top: var(--padding-y-md);
    padding-bottom: var(--padding-y-md);

    @include media-breakpoint-down(lg) {
      padding-top: var(--padding-y-md);
      padding-bottom: var(--padding-y-md);
    }
  }

  &.--spacing-sm {
    --padding-y-sm: #{general.$spacing * 3};

    padding-top: var(--padding-y-sm);
    padding-bottom: var(--padding-y-sm);

    @include media-breakpoint-down(lg) {
      padding-top: var(--padding-y-sm);
      padding-bottom: var(--padding-y-sm);
    }
  }

  &.--small-container {

    @include media-breakpoint-up(xl) {

      .container {
        max-width: 864px;
      }
    }
  }
  /* stylelint-disable no-descending-specificity */

  &.--divider {

    .base-section__container {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        display: block;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 32px);
        height: 1px;
        background-color: colors.$gray-200;

        @include media-breakpoint-up(md) {
          width: calc(100% - 48px);
        }
      }
    }
  }

  &.--divider-top {

    .base-section__container {

      &::after {
        top: calc(var(--padding-y) * -1);
      }
    }

    &.--spacing-md {

      .base-section__container {

        &::after {
          top: calc(var(--padding-y-md) * -1);
        }
      }
    }
  }

  &.--divider-bottom {

    .base-section__container {

      &::after {
        bottom: calc(var(--padding-y) * -1);
      }
    }

    &.--spacing-md {

      .base-section__container {

        &::after {
          bottom: calc(var(--padding-y-md) * -1);
        }
      }
    }
  }
  /* stylelint-enable no-descending-specificity */
}

.base-section__sort {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 8px;
  padding-right: 16px;

  @include media-breakpoint-down(md) {
    padding-right: 8px;
  }

  .dropdown__wrapper {
    margin-left: auto;
  }
}
