@use "./../../../scss/settings/s-colors.scss" as colors;

.s-posts {
  display: flex;
  flex-direction: column;

  .f-search {
    max-width: 550px;
    margin-bottom: 40px;
  }

  .dropdown__wrapper {
    display: flex;
    justify-content: flex-end;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      height: 1px;
      width: 100%;
      bottom: 0;
      left: 0;
      background-color: colors.$gray-200;
    }
  }
}
